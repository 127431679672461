import { useContext, createContext, PropsWithChildren, useEffect } from 'react';
import TagManager from 'react-gtm-module';
import { useLocalStorage } from '@hooks/use-local-storage';
import { useUser } from '@context/user';
import useFilters from '@hooks/use-filters';
import { useRouter } from 'next/router';
import { STATUS } from 'src/planning/shifts/constants';

export type ShiftDeadlineType = 'duration' | 'static-hour';
export type ShiftDirection = 'to_office' | 'from_office';

export type DeadlineFormData = {
  deadlineType: SelectedShiftDeadlineType;
  deadlineHours?: number;
  deadlineMinutes?: number;
};

export type SelectedShiftDeadlineType = 'default' | 'custom';

export enum SHIFT_DIRECTION {
  FROM_OFFICE = 'from_office',
  TO_OFFICE = 'to_office',
}

export enum SELECTED_DATE_TAB {
  TODAY = 'Today',
  TOMORROW = 'Tomorrow',
  CUSTOM = 'Custom',
}

export enum SELECTED_WEEKDAY {
  MON = 'Mon',
  TUE = 'Tue',
  WED = 'Wed',
  THU = 'Thu',
  FRI = 'Fri',
  SAT = 'Sat',
  SUN = 'Sun',
}

type ManagedCorporates =
  | {
      id: string;
      name: string;
      shiftReservationEnabled: boolean;
      allowDeleteAllBusinessProfiles: boolean;
      shiftType: string;
      fromOfficeShiftDeadline: {
        type: ShiftDeadlineType;
        value: number;
      };
      toOfficeShiftDeadline: {
        type: ShiftDeadlineType;
        value: number;
      };
    }[]
  | [];

type ShiftsFiltersProps = {
  corporate: string | null;
  date: Date | string;
  startDate?: Date | string;
  endDate?: Date | string;
  dateTab: SELECTED_DATE_TAB;
  direction: SHIFT_DIRECTION | null;
  status: STATUS;
};

type PlanningContextProps = {
  selectedCorporateId: string | null;
  setSelectedCorporate: (corporate: string | null) => void;
  managedCorporates: ManagedCorporates;
  shiftsFilters: ShiftsFiltersProps;
  setShiftsFilters: (filters: Partial<ShiftsFiltersProps>) => void;
};

const PlanningContext = createContext<PlanningContextProps>({
  selectedCorporateId: null,
  setSelectedCorporate: () => undefined as void,
  managedCorporates: [],
  shiftsFilters: null,
  setShiftsFilters: () => undefined as void,
});

export const PlanningProvider = ({ children }: PropsWithChildren<unknown>) => {
  const router = useRouter();
  const { setValue: setStoredCorporate, storedValue: storedCorporateId } = useLocalStorage<string | null>(
    '__SELECTED_CORPORATE__',
  );
  const { user } = useUser();
  const isPlanningModule = router.asPath.includes('planning');

  const [shiftsFilters, setShiftsFilters] = useFilters<ShiftsFiltersProps>(
    {
      corporate: storedCorporateId,
      dateTab: SELECTED_DATE_TAB.TODAY,
      date: new Date(),
    },
    isPlanningModule,
  );

  const managedCorporates = user?.accountManagedCorporates ?? [];

  useEffect(() => {
    if (shiftsFilters.corporate) {
      setStoredCorporate(shiftsFilters.corporate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shiftsFilters.corporate]);

  useEffect(() => {
    const dataLayer: { [key: string]: string } = {};

    storedCorporateId && (dataLayer['corporate_id'] = storedCorporateId);
    user?.id && (dataLayer['user_id'] = user.id);
    shiftsFilters?.dateTab && (dataLayer['selected_date_tab'] = shiftsFilters.dateTab);
    shiftsFilters?.date && (dataLayer['single_date'] = shiftsFilters?.date?.toString());
    shiftsFilters?.startDate && (dataLayer['start_date'] = shiftsFilters?.startDate?.toString());
    shiftsFilters?.endDate && (dataLayer['end_date'] = shiftsFilters?.endDate?.toString());
    shiftsFilters?.direction && (dataLayer['shift_direction'] = shiftsFilters.direction);
    shiftsFilters?.status && (dataLayer['status'] = shiftsFilters.status);

    if (Object.keys(dataLayer).length !== 0) {
      TagManager.dataLayer({
        dataLayer,
      });
    }
  }, [shiftsFilters?.date, shiftsFilters.dateTab, shiftsFilters.direction, storedCorporateId, user?.id]);

  return (
    <PlanningContext.Provider
      value={{
        selectedCorporateId: shiftsFilters.corporate,
        managedCorporates,
        setSelectedCorporate: corporate => {
          if (corporate) setShiftsFilters({ corporate });
        },
        shiftsFilters,
        setShiftsFilters,
      }}
    >
      {children}
    </PlanningContext.Provider>
  );
};

export const usePlanning = () => useContext(PlanningContext);
