import { PropsWithChildren } from 'react';

import { useApp } from '@context/app';
import { Sidebar } from '@shared/sidebar';
import InvoiceHeader from '@invoice/header';

export const InvoiceLayout = ({ children }: PropsWithChildren<unknown>) => {
  const { isSidebarOpen } = useApp();

  return (
    <>
      <InvoiceHeader />
      <main
        sx={{
          display: 'flex',
          height: '100%',
          aside: {
            minWidth: !isSidebarOpen && '80px !important',
          },
        }}
      >
        <>
          <Sidebar />
          <section sx={{ flex: 1, p: 'spacing-m' }}>{children}</section>
        </>
      </main>
    </>
  );
};
