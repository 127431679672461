import { FC, useMemo } from 'react';
import { Menu, MenuItem } from '@swvl/menu';
import { useCustomers } from '@context/customers';
import { useUser } from '@context/user';
import Header from '@shared/header';
import { useLogout } from '@shared/login/resources';
import { useRouter } from 'next/router';
import { corporates, createCorporate } from '@shared/paths';

const CustomersHeader: FC = () => {
  const router = useRouter();
  const { user } = useUser();
  const { selectedCorporateId, managedCorporates, setSelectedCorporate } = useCustomers();

  const { mutate: onLogout } = useLogout();
  const selectedCorporate = useMemo(
    () => managedCorporates.find(corporate => corporate.id === selectedCorporateId),
    [selectedCorporateId, managedCorporates],
  );

  const isCorporatesPage = router.asPath.startsWith(corporates);
  const isCreateCorporatesPage = router.asPath.startsWith(createCorporate);

  return (
    <Header>
      <Header.Modules />
      <Header.SettingsWrapper>
        {!isCorporatesPage && !isCreateCorporatesPage && (
          <Menu
            menuButton={
              <div
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  fontFamily: 'heading',
                }}
              >
                {selectedCorporateId ? selectedCorporate?.name : 'Select Corporate'}
              </div>
            }
            data-test-name="corporates-menu"
          >
            {managedCorporates.map(it => {
              return (
                <MenuItem
                  onClick={() => {
                    setSelectedCorporate(it.id);
                  }}
                  key={it.id}
                  data-test-name="corporates-menu-item"
                  data-test-id={it.id}
                >
                  {it.name}
                </MenuItem>
              );
            })}
          </Menu>
        )}
        <Header.AccountMenu
          user={{
            name: user?.name,
            picture: user?.picture,
          }}
          onLogout={onLogout}
        />
      </Header.SettingsWrapper>
    </Header>
  );
};

export default CustomersHeader;
