import { PropsWithChildren } from 'react';
import { useRouter } from 'next/router';
import { useApp } from '@context/app';
import { Sidebar } from '@shared/sidebar';
import FleetHeader from '@fleet/header';

export const FleetLayout = ({ children }: PropsWithChildren<unknown>) => {
  const router = useRouter();
  const { isSidebarOpen } = useApp();

  return (
    <>
      <FleetHeader />
      <main
        sx={{
          display: 'flex',
          height: '100%',
          aside: {
            minWidth: !isSidebarOpen && '80px !important',
          },
        }}
      >
        <>
          <Sidebar />
          <section
            sx={{
              flex: 1,
              padding:
                router.pathname.includes('recommended-map') || router.pathname.includes('single-ride-map')
                  ? 0
                  : 'spacing-m',
            }}
          >
            {children}
          </section>
        </>
      </main>
    </>
  );
};
