import { useContext, createContext, PropsWithChildren, useState, SetStateAction, Dispatch, useEffect } from 'react';
import noop from 'lodash/noop';
import { SelectedRole } from '@access-control/users/types';

type SelectedRoles = {
  [key: string]: SelectedRole;
};

type AccessControlUsersContextProps = {
  selectedRoles: SelectedRoles;
  selectedRolesNames: string[];
  hasError: boolean;
  hasRoles: boolean;
  setSelectedRoles: Dispatch<SetStateAction<SelectedRoles>>;
  setSelectedRolesNames: Dispatch<SetStateAction<string[]>>;
  reset: () => void;
};

const AccessControlUSersContext = createContext<AccessControlUsersContextProps>({
  selectedRolesNames: [],
  selectedRoles: {},
  hasRoles: false,
  hasError: false,
  setSelectedRoles: noop,
  setSelectedRolesNames: noop,
  reset: noop,
});

export const AccessControlUsersProvider = ({ children }: PropsWithChildren<unknown>) => {
  const [selectedRoles, setSelectedRoles] = useState({});
  const [selectedRolesNames, setSelectedRolesNames] = useState([]);
  const [hasError, setHasError] = useState(false);
  const [hasRoles, setHasRoles] = useState(false);

  // methods
  const reset = () => {
    setSelectedRoles({});
    setSelectedRolesNames([]);
  };

  useEffect(() => {
    setHasRoles(Object.entries(selectedRoles).length > 0);
    setHasError(
      Object.entries(selectedRoles).some(([, value]) => {
        const roleValue = value as { hasError: boolean };
        return roleValue.hasError;
      }),
    );
  }, [selectedRoles]);
  return (
    <AccessControlUSersContext.Provider
      value={{
        selectedRolesNames,
        selectedRoles,
        setSelectedRoles,
        setSelectedRolesNames,
        hasError,
        hasRoles,
        reset,
      }}
    >
      {children}
    </AccessControlUSersContext.Provider>
  );
};

export const useAccessControlUsers = () => useContext(AccessControlUSersContext);
