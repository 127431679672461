import { useContext, createContext, PropsWithChildren, useState } from 'react';
import { useRouter } from 'next/router';

import { ROUTING_STATUS as ROUTING_STATUS_EMPLOYEES, EMPLOYEE_VIEW } from '@customers/employees-profile/constants';
import useFilters from '@hooks/use-filters';
import { instrumentationsHandler } from '@utils/instrumentations';
import { GroupProps } from '@customers/employees-group/resource';
import { Employee } from '@customers/employees-profile/types';

type EmployeesContextProps = {
  employeesFilters: EmployeesFiltersProps | null;
  setEmployeesFilters: (filters: Partial<EmployeesFiltersProps>) => void;
  isGroupDrawerOpen: boolean;
  handleGroupDrawer: (val: boolean, group?: GroupProps) => void;
  isEditEmployeeDrawerOpen: boolean;
  isBulkUpdateDrawerOpen: boolean;
  selectedEmployee: Employee;
  employeeAction: EMPLOYEE_VIEW;
  handleEditEmployeeDrawer: (val: boolean, editedEmployee?: Employee) => void;
  handleBulkUpdateDrawer: (val: boolean) => void;
  isAddEmployeeDrawerOpen: boolean;
  handleAddEmployeeDrawer: (val: boolean) => void;
};

type EmployeesFiltersProps = {
  activeTab: ROUTING_STATUS_EMPLOYEES;
  station?: string;
  group?: string;
  editGroup?: GroupProps;
};

const EmployeesContext = createContext<EmployeesContextProps>({
  employeesFilters: null,
  setEmployeesFilters: () => undefined as void,
  isGroupDrawerOpen: false,
  handleGroupDrawer: () => undefined as void,
  isEditEmployeeDrawerOpen: false,
  isBulkUpdateDrawerOpen: false,
  selectedEmployee: null,
  employeeAction: null,
  handleEditEmployeeDrawer: () => undefined as void,
  handleBulkUpdateDrawer: () => undefined as void,
  isAddEmployeeDrawerOpen: false,
  handleAddEmployeeDrawer: () => undefined as void,
});

export const EmployeesProvider = ({ children }: PropsWithChildren<unknown>) => {
  const router = useRouter();

  const [isGroupDrawerOpen, setIsGroupDrawerOpen] = useState(false);
  const [isEditEmployeeDrawerOpen, setIsEditEmployeeDrawerOpen] = useState(false);
  const [isBulkUpdateDrawerOpen, setIsBulkUpdateDrawerOpen] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState<Employee>(null);
  const [employeeAction, setEmployeeAction] = useState<EMPLOYEE_VIEW>(null);
  const [isAddEmployeeDrawerOpen, setIsAddEmployeeDrawerOpen] = useState(false);
  const [employeesFilters, setEmployeesFilters] = useFilters<EmployeesFiltersProps>(
    {
      activeTab: ROUTING_STATUS_EMPLOYEES.EMPLOYEES,
      editGroup: null,
    },
    router.asPath.includes('customers'),
  );

  const handleGroupDrawer = (val: boolean, group?: GroupProps) => {
    setIsGroupDrawerOpen(val);
    setEmployeesFilters({ ...employeesFilters, editGroup: group || undefined });
  };

  const handleEditEmployeeDrawer = (val: boolean, employee?: Employee) => {
    setIsEditEmployeeDrawerOpen(val);
    setSelectedEmployee(employee ?? null);
    setEmployeeAction(EMPLOYEE_VIEW.EDIT_EMPLOYEE);
    if (!val) {
      setEmployeeAction(null);
    }
  };

  const handleBulkUpdateDrawer = (val: boolean) => {
    setIsBulkUpdateDrawerOpen(val);
    if (val === true) {
      instrumentationsHandler('click_bulk_upload');
    }
    setEmployeeAction(EMPLOYEE_VIEW.BULK_UPDATE_EMPLOYEES);
    if (!val) {
      setEmployeeAction(null);
    }
  };

  const handleAddEmployeeDrawer = (val: boolean) => {
    setIsAddEmployeeDrawerOpen(val);
    setEmployeeAction(EMPLOYEE_VIEW.ADD_EMPLOYEE);
    if (!val) {
      setEmployeeAction(null);
    }
  };

  return (
    <EmployeesContext.Provider
      value={{
        employeesFilters,
        setEmployeesFilters,
        isGroupDrawerOpen,
        handleGroupDrawer,
        isEditEmployeeDrawerOpen,
        isBulkUpdateDrawerOpen,
        selectedEmployee,
        employeeAction,
        handleEditEmployeeDrawer,
        handleBulkUpdateDrawer,
        isAddEmployeeDrawerOpen,
        handleAddEmployeeDrawer,
      }}
    >
      {children}
    </EmployeesContext.Provider>
  );
};

export const useEmployees = () => useContext(EmployeesContext);
