import { PropsWithChildren } from 'react';
import SupplyHeader from '@supply/header';
import { useApp } from '@context/app';
import { Sidebar } from '@shared/sidebar';
import { useRouter } from 'next/router';

export const SupplyLayout = ({ children }: PropsWithChildren<unknown>) => {
  const { isSidebarOpen } = useApp();
  const router = useRouter();

  return (
    <>
      <SupplyHeader />
      <main
        sx={{
          display: 'flex',
          height: '100%',
          aside: {
            minWidth: !isSidebarOpen && '80px !important',
          },
        }}
      >
        <>
          {router.pathname.includes('generate-plans-on-boarding') ? null : <Sidebar />}
          <section sx={{ flex: 1, p: router.pathname.includes('generate-plans-on-boarding')? null: 'spacing-m' }}>{children}</section>
        </>
      </main>
    </>
  );
};
