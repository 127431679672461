import { useContext, createContext, PropsWithChildren, useEffect } from 'react';
import { useLocalStorage } from '@hooks/use-local-storage';
import { useUser } from '@context/user';
import useFilters from '@hooks/use-filters';
import { useRouter } from 'next/router';
import { useCorporateDetails, CorporateModel } from '@customers/corporate/resources';

type CustomersContextProps = {
  selectedCorporateId: string | null;
  selectedCorporateData?: CorporateModel;
  setSelectedCorporate: (corporate: string | null) => void;
  managedCorporates: { id: string; name: string; shiftReservationEnabled: boolean }[] | [];
};

type CustomersFiltersProps = {
  corporate: string | null;
};

const CustomersContext = createContext<CustomersContextProps>({
  selectedCorporateId: null,
  selectedCorporateData: null,
  setSelectedCorporate: () => undefined as void,
  managedCorporates: [],
});

export const CustomersProvider = ({ children }: PropsWithChildren<unknown>) => {
  const router = useRouter();
  const { setValue: setStoredCorporate, storedValue: storedCorporateId } = useLocalStorage<string | null>(
    '__SELECTED_CORPORATE__',
  );
  const { user } = useUser();
  const [customersFilters, setCustomersFilters] = useFilters<CustomersFiltersProps>(
    {
      corporate: storedCorporateId,
    },
    router.asPath.includes('customers'),
  );

  const { data: selectedCorporateData } = useCorporateDetails(customersFilters.corporate);
  const managedCorporates = user?.accountManagedCorporates ?? [];

  useEffect(() => {
    if (customersFilters.corporate) {
      setStoredCorporate(customersFilters.corporate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customersFilters.corporate]);

  return (
    <CustomersContext.Provider
      value={{
        selectedCorporateId: customersFilters.corporate,
        selectedCorporateData,
        managedCorporates,
        setSelectedCorporate: corporate => {
          if (corporate) setCustomersFilters({ corporate });
        },
      }}
    >
      {children}
    </CustomersContext.Provider>
  );
};

export const useCustomers = () => useContext(CustomersContext);
