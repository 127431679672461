import { FC, useState, useEffect } from 'react';
import { useRouter } from 'next/router';
import { Button } from '@swvl/button';
import ErrorOverLay from '@shared/error-overlay';
export type RestrictedRoute = {
  name: string;
  condition: boolean;
};

const RestrictRouteGuard: FC<{ children: React.ReactElement; routes: RestrictedRoute[] }> = ({ children, routes }) => {
  const router = useRouter();
  const [showError, setShowError] = useState(false);

  useEffect(() => {
    if (router.asPath) {
      setShowError(routes.some((route: RestrictedRoute) => router.asPath.includes(route.name) && route.condition));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.asPath]);
  return showError ? (
    <ErrorOverLay code={404} message={`This page could not be found.`}>
      <Button
        variant="secondary"
        size="medium"
        onClick={() => {
          router.push('/customers/reservations');
        }}
      >
        {'Return to Home'}
      </Button>
    </ErrorOverLay>
  ) : (
    children
  );
};

export default RestrictRouteGuard;
