import env from '@beam-australia/react-env';
import { AxiosRequestConfig, AxiosResponse } from 'axios';
const accessControlBaseURL = env('ACCESS_CONTROL_API_URL');
import { PERSONA_SCOPE } from '@access-control/constants';

export const mergeConfig = (config: AxiosRequestConfig, addPersonaScope?: boolean): AxiosRequestConfig => {
  const { params } = config;
  if (addPersonaScope) {
    if (params) params['scope'] = PERSONA_SCOPE;
    else config['params'] = { scope: PERSONA_SCOPE };
  }
  const mergedConfig: AxiosRequestConfig = {
    ...config,
    baseURL: accessControlBaseURL,
  };
  return mergedConfig;
};

export const addResourcePrefix = (url: string, resource: 'user' | 'dashboard'): string => {
  return `${resource}/v1/${url}`;
};

export const getNameInitials = (name: string) => {
  const [firstWord, secondWord] = (name || '').split(' ');
  return firstWord[0] + (secondWord ? secondWord[0] : '');
};

export const AccessControlQueryRetry = (failureCount: number, error: AxiosResponse) => {
  if (error?.status === 403) return false;
  else return false;
};
