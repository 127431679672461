import { useContext, createContext, PropsWithChildren } from 'react';
import { useUserInfo, UserData } from '@shared/header/resources';

type UserContextProps = {
  user: UserData | undefined;
};

const UserContext = createContext<UserContextProps>({
  user: undefined,
});

export const UserProvider = ({ children }: PropsWithChildren<unknown>) => {
  const { data } = useUserInfo();

  return <UserContext.Provider value={{ user: data }}>{children}</UserContext.Provider>;
};

export const useUser = () => useContext(UserContext);
