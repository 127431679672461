import { PropsWithChildren } from 'react';
import BulkActionsHeader from '@bulk-actions/header';
import { Sidebar } from '@shared/sidebar';

export const BulkActionsLayout = ({ children }: PropsWithChildren<unknown>) => {
  return (
    <>
      <BulkActionsHeader />
      <main
        sx={{
          display: 'flex',
          height: '100%',
        }}
      >
        <>
          <Sidebar />
          <section sx={{ flex: 1 }}>{children}</section>
        </>
      </main>
    </>
  );
};
