import { SideNav } from '@swvl/navigation';
import { SettingsSuggestIcon } from '@swvl/icons';
import { useApp } from '@context/app';
import { useRouter } from 'next/router';
import { routingModules } from '@utils/routing-config';
import Can from '@shared/can-wrapper';
import { PAGES_PERMISSIONS } from '@access-control/constants';

export const Sidebar = () => {
  const { isSidebarOpen } = useApp();
  const router = useRouter();
  const modulesOrder = ['customers', 'fleet', 'planning', 'demand', 'supply', 'bulk-actions', 'private-bus', 'invoice'];
  const modulesKeys = Object.keys(routingModules).filter((key: string) => !routingModules[key].hideFromModulesListing);
  const orderedModulesKeys = modulesOrder.filter(module => modulesKeys.includes(module));
  return (
    <div
      sx={{
        '& > aside': {
          overflow: 'auto',
        },
      }}
    >
      <SideNav isExpanded={isSidebarOpen}>
        <SideNav.Section grow>
          {orderedModulesKeys.map(moduleKey => {
            const { path, title, subModules, icon } = routingModules[moduleKey];
            return (
              <SideNav.Item
                key={path}
                title={title}
                icon={icon}
                onClick={() => {
                  router.push(path);
                }}
                active={router.pathname.startsWith(path)}
              >
                {subModules.length
                  ? subModules.map(({ path, title, key, permission }) =>
                      permission ? (
                        <Can verb="a" permission={permission}>
                          <SideNav.SubItem
                            key={key}
                            title={title}
                            onClick={() => router.push(path)}
                            active={router.pathname.startsWith(path)}
                          />
                        </Can>
                      ) : (
                        <SideNav.SubItem
                          key={key}
                          title={title}
                          onClick={() => router.push(path)}
                          active={router.pathname.startsWith(path)}
                        />
                      ),
                    )
                  : null}
              </SideNav.Item>
            );
          })}
        </SideNav.Section>
        <SideNav.Section>
          <Can verb="a" permission={PAGES_PERMISSIONS['access-control']}>
            <SideNav.Item
              active={router.pathname.includes('access-control')}
              title={'Settings'}
              icon={<SettingsSuggestIcon />}
              onClick={() => {
                router.push('/access-control');
              }}
            />
          </Can>
        </SideNav.Section>
      </SideNav>
    </div>
  );
};
