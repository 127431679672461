import { useMutation, useQuery, useQueryClient } from 'react-query';
import { API } from '@utils/api-config';
import camelCase from 'camelcase-keys';
import { AxiosResponse } from 'axios';
import isNil from 'lodash/fp/isNil';
import { getErrorMessage } from '@utils';
import { showAlert } from '@swvl/alert';
import { useRouter } from 'next/router';

// Types: corporate
export type AccountManager = {
  _id?: string;
  name?: string;
  type?: string;
  phone?: string;
  id?: string;
};
export type Ride = {
  status: string;
  count: number;
};
export type RideReportResponse = {
  [key: string]: {
    count: number;
    rides: Array<Ride>;
  };
};
export type CorporateResponse = {
  _id: string;
  updatedAt: string;
  createdAt: string;
  name: string;
  city: {
    _id: string;
    name: string;
    currency: string;
    timezone: number;
  };
  invoice_email: string;
  private_ride_enabled: boolean;
  sla: string;
  account_managers: Array<AccountManager>;
  allow_skip_station_with_bookings: boolean;
  shift_type: string;
  shift_reservation_enabled: boolean;
  allow_delete_all_business_profiles: boolean;
  bulk_upload_details: [
    {
      _id: string;
      type: string;
      file_name: string;
    },
  ];
  total_subscriptions: number;
  emails: [string];
  numbers: [string];
  hotlines: [string];
};

export type CorporateModel = ReturnType<typeof corporateTransformer>;

// Types: corporate contract
export type CorporateContractResponse = {
  _id: string;
  excluded_holidays: [];
  included_holidays: [];
  status: string;
  deleted: boolean;
  corporate: {
    _id: string;
    city: {
      _id: string;
      currency: string;
    };
  };
  start_date: string;
  expiry_date: string;
  contract_type: 'busType' | 'busTypeRoute' | 'lumpsum';
  version: number;
  created_by: string;
  attachment: string;
  createdAt: string;
  updatedAt: string;
  __v: number;
  reviewed_by: string;
  subscriptions: [
    {
      pricing: {
        ride_price: number;
        captain_price: number;
      };
      subscription_type: string;
      deleted: boolean;
      _id: string;
      corporate: string;
      contract: string;
      __v: number;
      createdAt: string;
      updatedAt: string;
      id: string;
    },
  ];
  deduction_plan: null;
};

// Types: ActiveCaptains
export type ActiveCaptainsCorporateResponse = {
  activeCaptainsCount: number;
  inactiveCaptainsCount: number;
  lastMonthsCount: number;
  statsLastUpdatedDate: string;
  thisMonthsCount: number;
  totalUniqueCaptains: number;
};

export type CorporateRoutesResponse = {
  thisMonthsCount: number;
  lastMonthsCount: number;
  statsLastUpdatedDate: string;
};

export type CorporateContractModel = ReturnType<typeof corporateContractTransformer>;

// Transformer: contract
const ridesReportTransformer = (data: RideReportResponse) => camelCase(data, { deep: true });
const corporateTransformer = (data: CorporateResponse) => camelCase(data, { deep: true });
const corporateContractTransformer = (data: CorporateContractResponse) => camelCase(data, { deep: true });
const activeCaptainsTransformer = (data: ActiveCaptainsCorporateResponse) => camelCase(data, { deep: true });
const routesTransformer = (data: CorporateRoutesResponse) => camelCase(data, { deep: true });

// Requests:
const fetchCorporate = async ({ corporateId }: { corporateId: string }): Promise<CorporateModel> => {
  const response = await API.get<AxiosResponse, { data: CorporateResponse }>(`/corporate/${corporateId}`);
  return corporateTransformer(response.data);
};

const fetchRidesReports = async ({ corporateId }: { corporateId: string }): Promise<RideReportResponse> => {
  const corporates = [corporateId];
  const response = await API.get<AxiosResponse, { data: RideReportResponse }>(`ride/count/monthly`, {
    params: {
      corporates,
    },
  });
  return ridesReportTransformer(response.data);
};

const fetchCorporateContract = async ({ corporateId }: { corporateId: string }): Promise<CorporateContractModel> => {
  const response = await API.get<AxiosResponse, { data: CorporateContractResponse }>(
    `corporate_contract/corporate/${corporateId}/active`,
  );
  return corporateContractTransformer(response.data);
};

const deleteAccountManager = async ({
  corporateId,
  account_managers,
}: {
  corporateId: string;
  account_managers: Array<AccountManager>;
}) => {
  const data = { account_managers: account_managers };
  await API.patch<AxiosResponse>(`/corporate/${corporateId}`, data);
};

const fetchActiveCaptains = async ({ corporateID }: { corporateID: string }) => {
  const params = { corporates: [corporateID] };
  const response = await API.get<AxiosResponse, { data: ActiveCaptainsCorporateResponse }>(
    '/captain/count/monthly/difference/',
    { params },
  );
  return activeCaptainsTransformer(response.data);
};

const fetchRoutes = async ({ corporateID }: { corporateID: string }) => {
  const params = { corporates: [corporateID] };
  const response = await API.get<AxiosResponse, { data: CorporateRoutesResponse }>('/route/count/monthly/difference/', {
    params,
  });

  return routesTransformer(response.data);
};

// Custom Hook:
const useCorporateDetails = (selectedCorporateId?: string) => {
  const router = useRouter();
  const corporateId = selectedCorporateId ?? router.query.corporateId;
  return useQuery(['corporate/details', corporateId], () => fetchCorporate({ corporateId: corporateId as string }), {
    enabled: !isNil(corporateId),
    onError: (response: AxiosResponse) => {
      const message = getErrorMessage(response);
      showAlert({
        message,
        type: 'error',
        id: 'alert-error',
      });
    },
  });
};

const useRidesReport = () => {
  const router = useRouter();
  const { corporateId } = router.query;
  return useQuery(['rides/report', corporateId], () => fetchRidesReports({ corporateId: corporateId as string }), {
    enabled: !isNil(corporateId),
    onError: (response: AxiosResponse) => {
      const message = getErrorMessage(response);
      showAlert({
        message,
        type: 'error',
        id: 'alert-error',
      });
    },
  });
};

const useCorporateContract = () => {
  const router = useRouter();
  const { corporateId } = router.query;
  return useQuery(
    ['corporate/contracts', corporateId],
    () => fetchCorporateContract({ corporateId: corporateId as string }),
    {
      retry: false,
      enabled: !isNil(corporateId),
      onError: (response: AxiosResponse) => {
        const message = getErrorMessage(response);
        showAlert({
          message,
          type: 'error',
          id: 'alert-error',
        });
      },
    },
  );
};

const useDeleteAccountManager = (accountManagerName: string) => {
  const queryClient = useQueryClient();
  return useMutation(deleteAccountManager, {
    mutationKey: 'accountManager/delete',
    onSuccess: () => {
      showAlert({
        message: `You have successfully deleted ${accountManagerName} from this account.`,
        id: 'alert-success',
        variant: 'success',
        mode: 'dark',
      });
      queryClient.invalidateQueries('corporate/details');
    },
    onError: (response: AxiosResponse) => {
      const message = getErrorMessage(response);
      showAlert({
        message,
        id: 'alert-error',
        variant: 'error',
        mode: 'dark',
      });
    },
  });
};

const useActiveCaptains = () => {
  const router = useRouter();
  const { corporateId } = router.query;
  return useQuery(
    ['corporate/activeCaptains', corporateId],
    () => fetchActiveCaptains({ corporateID: corporateId as string }),
    {
      enabled: !isNil(corporateId),
      onError: (response: AxiosResponse) => {
        const message = getErrorMessage(response);
        showAlert({
          message,
          type: 'error',
          id: 'alert-error',
        });
      },
    },
  );
};

const useRoutes = () => {
  const router = useRouter();
  const { corporateId } = router.query;
  return useQuery(['corporate/routes', corporateId], () => fetchRoutes({ corporateID: corporateId as string }), {
    enabled: !isNil(corporateId),
    onError: (response: AxiosResponse) => {
      const message = getErrorMessage(response);
      showAlert({
        message,
        type: 'error',
        id: 'alert-error',
      });
    },
  });
};

export {
  useCorporateDetails,
  fetchCorporate,
  useCorporateContract,
  fetchCorporateContract,
  useDeleteAccountManager,
  useRidesReport,
  fetchActiveCaptains,
  useActiveCaptains,
  fetchRoutes,
  useRoutes,
};
