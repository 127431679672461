import { createContext, PropsWithChildren, useState, useContext, useMemo } from 'react';

const NAVIGATION_SIDEBAR_WIDTH = {
  EXTENDED: '240px' as const,
  COLLAPSED: '80px' as const,
};
type AppContextProps = {
  isSidebarOpen: boolean;
  toggleSidebar: () => void;
  sidebarWidth: string;
};

const AppContext = createContext<AppContextProps>({
  isSidebarOpen: false,
  toggleSidebar: () => undefined as void,
  sidebarWidth: NAVIGATION_SIDEBAR_WIDTH.COLLAPSED,
});

export const AppProvider = ({ children }: PropsWithChildren<unknown>) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const toggleSidebar = () => setIsSidebarOpen(isSidebarOpen => !isSidebarOpen);
  const sidebarWidth = useMemo(
    () => (isSidebarOpen ? NAVIGATION_SIDEBAR_WIDTH.EXTENDED : NAVIGATION_SIDEBAR_WIDTH.COLLAPSED),
    [isSidebarOpen],
  );

  return <AppContext.Provider value={{ isSidebarOpen, toggleSidebar, sidebarWidth }}>{children}</AppContext.Provider>;
};

export const useApp = () => useContext(AppContext);
