import { API } from '@utils/api-config';
import { AxiosResponse } from 'axios';
import {
  AssignNewRolePayload,
  RoleWithAttributesResponse,
  SearchUserResponse,
  SearchUsersListModel,
  UsersApiResponse,
  FetchAllUsers,
  UserDetailsResponse,
  EditUserRolePayload,
  SearchAttributeAutocomplete,
} from '@access-control/users/types';
import { AccessControlQueryRetry, addResourcePrefix, getNameInitials, mergeConfig } from '@access-control/utils';
import { useMutation, useQuery } from 'react-query';
import { showAlert } from '@swvl/alert';
import { getErrorMessage } from '@utils';
import { UserPermissions } from '@swvl/gandalf-can';

// Utilities
const transformResponse = ({ data }: AxiosResponse<SearchUserResponse>): SearchUsersListModel[] => {
  return data?.hits.map(({ _id, _source }) => ({
    label: `${_source.email || ''}-${_source.name}-${getNameInitials(_source.name)}`,
    value: _id,
  }));
};
// Resources
export const searchUsers = async (query: string): Promise<SearchUsersListModel[]> => {
  const params = { query };
  const response = await API.get<SearchUserResponse>(
    addResourcePrefix('user/autocomplete', 'dashboard'),
    mergeConfig({
      params,
    }),
  );
  return transformResponse(response);
};

export const searchAttributesAutoComplete = async (
  query: string,
  attributeId: string,
): Promise<SearchAttributeAutocomplete[]> => {
  const params = { query };
  const response = await API.get<SearchAttributeAutocomplete[]>(
    addResourcePrefix(`attribute/${attributeId}/values/autocomplete`, 'dashboard'),
    mergeConfig({
      params,
    }),
  );
  return response?.data;
};

const fetchAllUsers = async ({ page, limit }: FetchAllUsers) => {
  const params = { page, limit };
  const { data } = await API.get<UsersApiResponse>(addResourcePrefix(`user`, 'dashboard'), mergeConfig({ params }));
  return data;
};

export const fetchUserBundle = async () => {
  const { data } = await API.get<UserPermissions>(addResourcePrefix(`bundle`, 'user'), mergeConfig({}));
  return data;
};

const fetchRoleAttributes = async ({ id }: { id: number }) => {
  const params = { 'with[]': 'attributes' };
  const { data } = await API.get<RoleWithAttributesResponse>(
    addResourcePrefix(`role/${id}`, 'dashboard'),
    mergeConfig({ params }, true),
  );
  return data;
};

const AssignNewRole = async (payload: AssignNewRolePayload) => {
  const response = await API.post<any>(
    addResourcePrefix(`role/bulk_assign`, 'dashboard'),
    payload.roles,
    mergeConfig({}, true),
  );
  return response;
};

const EditUserRole = async (payload: EditUserRolePayload) => {
  const response = await API.patch<any>(
    addResourcePrefix(`subject/user/${payload.userId}/roles`, 'dashboard'),
    payload.roles,
    mergeConfig({}, true),
  );
  return response;
};

const fetchUserDetails = async ({ userId }: { userId: string }) => {
  const { data } = await API.get<UserDetailsResponse>(
    addResourcePrefix(`user/${userId}`, 'dashboard'),
    mergeConfig({}),
  );
  return data;
};
// Hooks
export const useFetchAllUsers = ({ page, limit }: FetchAllUsers) => {
  return useQuery(['all-users', page, limit], () => fetchAllUsers({ page, limit }), {
    retry: AccessControlQueryRetry,
    onError: (error: AxiosResponse) => {
      const message = getErrorMessage(error);
      showAlert({
        id: 'all-users',
        message,
        type: 'error',
        mode: 'dark',
      });
    },
  });
};

export const useFetchRoleAttributes = ({
  id,
  isSelected,
  withAttributes,
}: {
  id: number;
  isSelected: boolean;
  withAttributes: boolean;
}) => {
  return useQuery(['role-attributes', id], () => fetchRoleAttributes({ id }), {
    enabled: !!isSelected && !!withAttributes,
    retry: AccessControlQueryRetry,
    onError: (error: AxiosResponse) => {
      const message = getErrorMessage(error);
      showAlert({
        id: 'role-attributes',
        message,
        type: 'error',
        mode: 'dark',
      });
    },
  });
};

export const useAssignNewRole = ({ onSuccessCb }: { onSuccessCb: () => void }) => {
  const mutation = useMutation(AssignNewRole, {
    retry: false,
    mutationKey: 'assign-new-role',
    onSuccess: () => {
      onSuccessCb();
    },
    onError: (error: AxiosResponse) => {
      const message = getErrorMessage(error);
      showAlert({
        id: 'assign-new-role',
        message,
        type: 'error',
        mode: 'dark',
      });
    },
  });
  return mutation;
};

export const useFetchUserDetails = ({ userId }: { userId: string }) => {
  return useQuery(['user-details', userId], () => fetchUserDetails({ userId }), {
    enabled: !!userId,
    retry: AccessControlQueryRetry,
    onError: (error: AxiosResponse) => {
      const message = getErrorMessage(error);
      showAlert({
        id: 'fetch-user-details',
        message,
        type: 'error',
        mode: 'dark',
      });
    },
  });
};

export const useEditUserRole = ({ onSuccessCb }: { onSuccessCb: () => void }) => {
  const mutation = useMutation(EditUserRole, {
    retry: false,
    mutationKey: 'edit-user-role',
    onSuccess: () => {
      onSuccessCb();
    },
    onError: (error: AxiosResponse) => {
      const message = getErrorMessage(error);
      showAlert({
        id: 'edit-user-role',
        message,
        type: 'error',
        mode: 'dark',
      });
    },
  });
  return mutation;
};
