import { RestrictedRoute } from '@shared/restrict-route-guard';
import env from '@beam-australia/react-env';
import { accessControl } from '@shared/paths';
const isAccessControlEnabled = env('IS_ACCESS_CONTROL_ENABLED');

export const weekdays = [
  { label: 'Su', value: 7 },
  { label: 'Mo', value: 1 },
  { label: 'Tu', value: 2 },
  { label: 'We', value: 3 },
  { label: 'Th', value: 4 },
  { label: 'Fr', value: 5 },
  { label: 'Sa', value: 6 },
];

export const DATE_FORMAT = 'dd MMM, yyyy';
export const DATE_TIME_FORMAT = 'dd MMM, yyyy - hh:mm a';
export const FULL_DATE_FORMAT = 'dd MMMM, yyyy';
export const TIME_FORMAT = 'hh:mm a';
export const MINUTES_IN_DAY = 1440;

export const RestrictedRoutes: RestrictedRoute[] = [
  {
    name: accessControl({ subModule: '' }),
    condition: isAccessControlEnabled === 'false',
  },
];

export const saasModulesPermissions = {
  'access-control': {
    viewModule: 'SaaS_Platform.Access_Management.Navigation.Module_view',
    viewUsersModule: 'SaaS_Platform.Access_Management.Navigation.Users_view',
    viewRolesModule: 'SaaS_Platform.Access_Management.Navigation.Roles_view',
    assignRoles: 'SaaS_Platform.Access_Management.Roles.Assign',
  },
};

export const AUTH_DATA_KEY = '__USER_AUTH_DATA__';
