import { PropsWithChildren } from 'react';
import { DemandProvider } from '@context/demand';
import DemandHeader from '@demand/header';
import { useApp } from '@context/app';
import { Sidebar } from '@shared/sidebar';

export const DemandLayout = ({ children }: PropsWithChildren<unknown>) => {
  const { isSidebarOpen } = useApp();

  return (
    <DemandProvider>
      <DemandHeader />
      <main
        sx={{
          display: 'flex',
          height: '100%',
          aside: {
            minWidth: !isSidebarOpen && '80px !important',
          },
        }}
      >
        <>
          <Sidebar />
          <section sx={{ flex: 1, p: 'spacing-m' }}>{children}</section>
        </>
      </main>
    </DemandProvider>
  );
};
