import { FC } from 'react';
import Image from 'next/image';
import Link from 'next/link';

import { Menu, MenuItem } from '@swvl/menu';
import { MenuIcon, Settings2Icon } from '@swvl/icons';
import { IconButton } from '@swvl/button';
import Avatar from '@swvl/avatar';
import { TopNavigation, TopNavigationItem } from '@swvl/navigation';

import { useApp } from '@context/app';
import { useTheme } from '@swvl/theme';
import { useRouter } from 'next/router';
import { useAuth } from '@context/auth';
import { useCan } from '@context/can';
import { createCorporate } from '@shared/paths';

import logo from 'public/images/swvl-logo.svg';
import { routingModules } from '@utils/routing-config';
import env from '@utils/env';
import Can from '@shared/can-wrapper';
import { saasModulesPermissions } from '@shared/constants';

type HeaderCompoundTypes = {
  Modules: FC;
  SettingsWrapper: FC;
  AccountMenu: FC<{
    user: {
      name?: string;
      picture?: string;
    };
    onLogout: (logoutData: { refresh_token: string }) => void;
  }>;
};

const isAccessControlEnabled = env('IS_ACCESS_CONTROL_ENABLED');

const Header: FC & HeaderCompoundTypes = props => {
  const { theme } = useTheme();
  return <TopNavigation sx={{ paddingInlineStart: 18, zIndex: theme.zIndices.fixed }} {...props} />;
};

const Modules: HeaderCompoundTypes['Modules'] = () => {
  const router = useRouter();
  const isCreateCorporatesPage = router.asPath.startsWith(createCorporate);
  const { toggleSidebar } = useApp();
  return (
    <div sx={{ display: 'flex', alignItems: 'center', minWidth: '700px' }}>
      {!isCreateCorporatesPage && (
        <IconButton
          type="button"
          icon={<MenuIcon />}
          sx={{
            svg: {
              width: 20,
            },
          }}
          onClick={() => toggleSidebar()}
        />
      )}
      <div sx={{ marginInlineStart: 22, marginInlineEnd: 36 }}>
        <Image src={logo} alt="Swvl Logo" />
      </div>
    </div>
  );
};

const SettingsWrapper: HeaderCompoundTypes['SettingsWrapper'] = props => (
  <div
    sx={{
      display: 'flex',
      alignItems: 'center',
      '& > *:nth-child(1n+2)': {
        marginInlineStart: 'spacing-m',
      },
    }}
    {...props}
  />
);

const AccountMenu: HeaderCompoundTypes['AccountMenu'] = ({ user, onLogout }) => {
  const { authData } = useAuth();
  const { canClient } = useCan();
  return (
    <Menu
      menuButton={
        <div
          sx={{
            display: 'flex',
            alignItems: 'center',
            fontFamily: 'heading',
          }}
        >
          <Avatar size={32} name={user?.name ?? ''} title={user?.name ?? ''} src={user?.picture ?? ''} />
        </div>
      }
      data-test-name="account-menu"
    >
      <MenuItem
        data-test-name="account-menu-logout"
        onClick={() => {
          canClient?.reset();
          onLogout({ refresh_token: authData?.refreshToken });
        }}
      >
        Logout
      </MenuItem>
    </Menu>
  );
};

Header.Modules = Modules;
Header.SettingsWrapper = SettingsWrapper;
Header.AccountMenu = AccountMenu;

export default Header;
