import { AxiosResponse } from 'axios';
import format from 'date-fns/format';
import { Dictionary } from 'lodash';
import isObject from 'lodash/fp/isObject';
import mapKeys from 'lodash/fp/mapKeys';
import mapValues from 'lodash/fp/mapValues';
import snakeCase from 'lodash/fp/snakeCase';
import isNil from 'lodash/isNil';
import isNull from 'lodash/isNull';
import { DATE_FORMAT, DATE_TIME_FORMAT, TIME_FORMAT } from '@shared/constants';

export type ResponseErrorData = {
  message?: string;
  error?: { message: string };
};
export const constants = {
  dateTimeFormat: 'dd/MM/yyyy hh:mm a',
  dateFormat: 'dd/MM/yyyy',
  timeFormat: 'hh:mm a',
  timeFormat24: 'HH:mm',
  weekDaysShort: ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'],
  weekDays: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
  datePickerPlaceholderFormat: 'E, LLL do',
  timeZoneOffset: new Date().getTimezoneOffset() * 60000,
};

export const convertMinutesAmPmTime = (time: number) => {
  const hoursIn24 = Math.floor(time / 60);
  const hoursIn12 = hoursIn24 % 12 || 12;
  const mins = Math.floor(time % 60);

  const minsString = mins >= 10 ? `${mins}` : `0${mins}`;
  const hoursString = hoursIn12 >= 10 ? `${hoursIn12}` : `0${hoursIn12}`;

  const isDayTime = hoursIn24 < 12 || hoursIn24 === 24;
  const amOrPm = isDayTime ? 'AM' : 'PM';

  return `${hoursString}:${minsString} ${amOrPm}`;
};

export const convertAmPmTimeTo24Time = (time: string) => {
  if (!time) return 0;
  // this regex allows for optional 0 in hours and minutes i.e it accepts: "h:m a", "hh:mm a", "hh:m a", "h:mm a"
  if (!time.match(/((1[0-2]|0?[1-9]):((0?[0-9]|[1-5][0-9])) ?([AaPp][Mm]))/)) return 0;
  const timeWithoutSpaces = time.split(' ').join('').toLowerCase();
  let hours = Number(timeWithoutSpaces.match(/^(\d+)/)[1]);
  const minutes = Number(timeWithoutSpaces.match(/:(\d+)/)[1]);
  const amOrPm = timeWithoutSpaces.match(/(am|pm)/)[0];
  if (amOrPm === 'pm' && hours < 12) {
    hours = hours + 12;
  }
  if (amOrPm === 'am' && hours === 12) {
    hours = hours - 12;
  }
  const h = hours > 10 ? hours : (`0${hours}` as string | number);
  const m = minutes > 10 ? minutes : (`0${minutes}` as string | number);
  return `${h}:${m}`;
};
export const convertAmPmTimeToMinutes = (time: string) => {
  const time24 = convertAmPmTimeTo24Time(time);
  if (!time24) return 0;
  const hours = Number(time24.split(':')[0]);
  const minutes = Number(time24.split(':')[1]);

  const timeInMinutes = hours * 60 + minutes;
  return timeInMinutes;
};

export const toSnakeCase = (object: Record<string, unknown>) => {
  const callback = (key: string) => {
    return snakeCase(key);
  };
  const mapKeysDeep = (obj: Record<string, unknown>): Record<string, unknown> =>
    mapValues((val: string) => {
      return isObject(val) ? mapKeysDeep(val) : val;
    })(mapKeys(callback)(obj) as Dictionary<string>);
  return mapKeysDeep(object);
};

export const minsToHours = (mins: number) => {
  if (!mins) {
    return '0 mins';
  }
  const m = Number(Math.floor(mins % 60).toFixed());
  const h = Number(Math.floor(mins / 60).toFixed());

  if (h > 0 && m > 0) {
    return `${h} hrs ${m} mins`;
  }
  if (h > 0) {
    return `${h} hrs`;
  }
  return `${m} mins`;
};
export const formatDateNoTimeZone = (dateString: string) => {
  const date = new Date(dateString);
  const dtDateOnly = new Date(date.valueOf() + date.getTimezoneOffset() * 60 * 1000);
  return format(dtDateOnly, DATE_FORMAT);
};

export const formatTimeNoDate = (dateString: string) => {
  const date = new Date(dateString);
  const dtDateOnly = new Date(date.valueOf() + date.getTimezoneOffset() * 60 * 1000);
  return format(dtDateOnly, TIME_FORMAT);
};

export const dateTimeFormat = (dateTimeString: string) => {
  const date = new Date(dateTimeString);
  const dtDateOnly = new Date(date.valueOf() + date.getTimezoneOffset() * 60 * 1000);
  return format(dtDateOnly, DATE_TIME_FORMAT);
};

export const timeConvertToAMPM = (time: string) => {
  let timeString = time;

  const hourEnd = timeString.indexOf(':');
  const H = +timeString.substring(0, hourEnd);
  let h = H % 12 || (12 as string | number);
  h = h < 10 ? `0${h}` : `${h}`;
  let m = +timeString.substring(hourEnd + 1, 5) as string | number;
  m = m < 10 ? `0${m}` : `${m}`;

  const ampm = H < 12 ? 'AM' : 'PM';
  timeString = h + ':' + m + ' ' + ampm;

  return timeString;
};

const DUMMY_DATE = '1999 10 10';

export const endTimeCalculator = (ride: { duration: string | number; startTime: string }) => {
  const { duration, startTime } = ride;
  // DUMMY_DATE const is being used to allow usage of the date object, as start time comes in hh:mm format only.
  const fullDate = new Date(`${DUMMY_DATE} ${startTime}`);
  fullDate.setMinutes(fullDate.getMinutes() + parseFloat(`${duration}`));
  const minutes = fullDate.getMinutes();
  const hours = fullDate.getHours();
  const endTime24 = `${hours >= 10 ? hours : `0${hours}`}:${minutes >= 10 ? minutes : `0${minutes}`}`;
  // we're going to return the time in the 24 hours format and use timeConvertToAMPM whenever needed to transform it into 12 hours
  // e.g. timeConvertToAMPM(endTimeCalculator({startTime, duration}));
  return endTime24;
};

export const pluralize = (count: number, text: string, suffix = 's'): string => {
  return `${count} ${text}${count !== 1 ? suffix : ''}`;
};

export const getErrorMessage = (errorResponse: AxiosResponse): string => {
  let message = '';
  if (errorResponse?.data) {
    message =
      (errorResponse.data as ResponseErrorData).message ||
      (errorResponse.data as ResponseErrorData).error?.message ||
      'Something went wrong , try again!';
  } else message = 'Something went wrong , try again!';
  return message;
};

export const getColumnFrom2dArr = <T>(arr2D: T[][], columnIndex: number): T[] => {
  const column: T[] = [];
  const arr2DRowlength = arr2D.length;

  for (let i = 0; i < arr2DRowlength; i++) {
    column.push(arr2D[i][columnIndex]);
  }

  return column;
};

export const setColumnIn2dArrWith = <T>(arr2D: T[][], columnIndex: number, value: T): T[][] => {
  const arr2DCopy = [...arr2D];
  const arr2DRowlength = arr2D.length;

  for (let i = 0; i < arr2DRowlength; i++) {
    arr2DCopy[i][columnIndex] = value;
  }

  return arr2DCopy;
};

/**
 * Return index of number that break the squences of numbers otherwise will return -1.
 *
 * Ex:
 *  if input is: [1, 2, 2, 4].
 *  output will be: 2.
 *
 *  if input is: [1, 2, 3, 4].
 *  output will be: -1.
 *
 */
export const isSquential = (numbers: number[]): number => {
  if (numbers.every(element => element === null)) return -1;

  for (let i = 0; i < numbers.length - 1; i++) {
    const number = numbers[i];
    const nextNumber = numbers[i + 1];
    if (!isNil(number) && !isNil(nextNumber) && numbers[i + 1] - numbers[i] !== 1) {
      return i + 1;
    }
  }
  return -1;
};

export const isArrayHasNull = <T>(numbers: T[]): number => {
  if (numbers.every(element => element === null)) return -1;
  return numbers.findIndex(value => isNull(value));
};

export const isLessThan = (min: number, max: number): boolean => {
  if (isNil(min) || isNil(max)) return true;

  if (min < max) return true;

  return false;
};

export const isGreaterThan = (min: number, max: number): boolean => {
  if (isNil(min) || isNil(max)) return true;

  if (max > min) return true;

  return false;
};

export const getNextCellFrom2dArr = <T>(arr2D: T[][], rowIndex: number, columnIndex: number): T => {
  const nextIndex = columnIndex + 1;
  if (Array.isArray(arr2D) && columnIndex in arr2D[0] && nextIndex in arr2D[0]) {
    return arr2D[rowIndex][nextIndex];
  }

  return;
};

export const getPrevCellFrom2dArr = <T>(arr2D: T[][], rowIndex: number, columnIndex: number): T => {
  const prevIndex = columnIndex - 1;
  if (Array.isArray(arr2D) && columnIndex in arr2D[0] && prevIndex in arr2D[0]) {
    return arr2D[rowIndex][prevIndex];
  }

  return;
};

export const preventNegativeInput = (e: React.KeyboardEvent<object> & React.ClipboardEvent<HTMLInputElement>) => {
  if (e.key === '-' || Number(e.clipboardData?.getData('text')) < 0) {
    e.preventDefault();
  }
};

export const convertArrToObj = <T>(arr: T[], keys: string[], removeEmpty = false): any => {
  const obj: any = {};
  if (arr.every(item => !item)) return;
  keys.map((key, index) => {
    if (removeEmpty && !arr[index]) return;
    obj[key] = arr[index];
  });

  return obj;
};

export const convert2DArrToArrOfObj = <T>(arr2D: T[][], keys: string[], removeEmpty = false): any[] => {
  const arrOfObj: T[] = [];
  const arr2DCopy = [...arr2D];
  arr2DCopy.map((item: T[]) => {
    const obj: any = convertArrToObj(item, keys, removeEmpty);
    if (obj) arrOfObj.push(obj);
  });
  return arrOfObj;
};

export const convertFromHoursToMilliSeconds = (hours: number): number => {
  return hours * 60 * 60 * 1000;
};

export const convertFromMilliSecondsToHours = (milliSeconds: number): number => {
  return Number((milliSeconds / (60 * 60 * 1000)).toFixed(1));
};

export const convertFromPoundsToPiastres = (pounds: number): number => {
  return pounds * 100;
};

export const convertFromPiastresToPounds = (piastres: number): number => {
  return Number((piastres / 100).toFixed(2));
};
