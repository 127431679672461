import { PropsWithChildren, useMemo } from 'react';
import AccessControlHeader from '@access-control/header';
import { useApp } from '@context/app';
import { useRouter } from 'next/router';
import { AccessControlUsersProvider } from '@context/access-control-users';
import { NewRoleProvider } from '@context/new-role';
import { Sidebar } from '@shared/sidebar';

export const AccessControlLayout = ({ children }: PropsWithChildren<unknown>) => {
  const { isSidebarOpen } = useApp();
  const router = useRouter();
  const CurrentProvider = useMemo(() => {
    return router.pathname.includes('roles') ? NewRoleProvider : AccessControlUsersProvider;
  }, [router.pathname]);
  return (
    <CurrentProvider>
      <AccessControlHeader />
      <main
        sx={{
          display: 'flex',
          height: '100%',
          aside: {
            minWidth: !isSidebarOpen && '80px !important',
          },
        }}
      >
        <>
          <Sidebar />
          <section sx={{ flex: 1, p: 'spacing-m' }}>{children}</section>
        </>
      </main>
    </CurrentProvider>
  );
};
