import { useState, useEffect, useMemo } from 'react';
import qs from 'qs';
import { useRouter } from 'next/router';

export default function useFilters<T>(
  initialValue: Partial<T>,
  enabled: boolean,
): [T, (newFilters: Partial<T>) => void] {
  const router = useRouter();
  const queryParams = useMemo(() => qs.parse(router.asPath.split('?')[1]), [router.asPath]);
  const mergedFilters: unknown = {
    ...initialValue,
    ...queryParams,
  };
  const [filters, setFilters] = useState<T>(mergedFilters as T);

  const updateFilters = (newFilters: Partial<T>) => {
    const allFilters = {
      ...queryParams,
      ...filters,
      ...newFilters,
    } as T;

    setFilters(allFilters);
    const allFiltersString = qs.stringify(allFilters);
    router.push({ query: allFiltersString }, undefined, {
      shallow: true,
    });
  };

  useEffect(() => {
    if (enabled) {
      const allFiltersString = qs.stringify(filters);
      router.push({ query: allFiltersString }, undefined, {
        shallow: true,
      });
    }
  }, [enabled]);

  return [filters, updateFilters];
}
