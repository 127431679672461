import { FC, useEffect, useState, useMemo } from 'react';
import { useUser } from '@context/user';
import { Menu, MenuItem } from '@swvl/menu';
import Header from '@shared/header';
import { useLogout } from '@shared/login/resources';
import { CitySource, useAutocompleteCity } from '@demand/core-pricing/resources';
import Autocomplete from '@swvl/autocomplete';
import { useDemand } from '@context/demand';

const DemandHeader: FC = () => {
  const { user } = useUser();
  const { mutate: onLogout } = useLogout();
  const [cityQuery, setCityQuery] = useState('');
  const { setCurrentCityId, selectedCorporateId, myCorporates, setSelectedCorporate } = useDemand();

  const { data: cityData, isLoading: isSuppliersLoading } = useAutocompleteCity(cityQuery);

  const handleCitySelect = (cityId: string) => {
    setCurrentCityId(cityId);
  };
  const selectedCorporate = useMemo(
    () => myCorporates.find(corporate => corporate.id === selectedCorporateId),
    [selectedCorporateId, myCorporates],
  );

  useEffect(() => {
    setCityQuery(user?.city.name);
  }, [user]);

  return (
    <Header>
      <Header.Modules />
      <Header.SettingsWrapper>
        <Menu
          menuButton={
            <div
              sx={{
                display: 'flex',
                alignItems: 'center',
                fontFamily: 'heading',
              }}
            >
              {selectedCorporateId ? selectedCorporate?.name : 'Select Corporate'}
            </div>
          }
          data-test-name="demand-corporates-menu"
        >
          {myCorporates.map(it => {
            return (
              <MenuItem
                onClick={() => {
                  setSelectedCorporate(it.id);
                }}
                key={it.id}
                data-test-name="demand-corporates-menu-item"
                data-test-id={it.id}
              >
                {it.name}
              </MenuItem>
            );
          })}
        </Menu>
        <Autocomplete
          suggestions={
            cityData?.hits?.map((city: CitySource) => ({
              label: city.name,
              value: city.id,
            })) || []
          }
          isLoading={isSuppliersLoading}
          onChangeQuery={input => setCityQuery(input)}
          onOptionSelect={option => {
            const selectedValue = option as string;
            handleCitySelect(selectedValue);
          }}
          variant="plain"
          height="compact"
          initialValue={cityQuery}
        />
        <Header.AccountMenu
          user={{
            name: user?.name,
            picture: user?.picture,
          }}
          onLogout={onLogout}
        />
      </Header.SettingsWrapper>
    </Header>
  );
};

export default DemandHeader;
