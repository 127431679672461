import { useState, createContext, useContext, PropsWithChildren, useEffect } from 'react';
import { useLocalStorage } from '@hooks/use-local-storage';
import type { AuthData } from '@shared/login/resources';
import { useRouter } from 'next/router';
import { AUTH_DATA_KEY } from '@shared/constants';
import { setApiAuthorizationHeader, useApiResponseInterceptor } from '@utils/api-config';

export type AuthContextType = {
  resetAuthData: () => void;
  authData: AuthData | null;
  saveAuthData: (data: AuthData) => void;
  redirectUrl?: string;
  isAuthorized: boolean;
};

export const AuthContext = createContext<AuthContextType>({
  authData: null,
  saveAuthData: () => undefined,
  resetAuthData: () => undefined,
  isAuthorized: false,
});

export const AuthProvider = ({ children }: PropsWithChildren<unknown>) => {
  const { storedValue: authData, setValue: setAuthData } = useLocalStorage<AuthData | null>(AUTH_DATA_KEY);
  const [isAuthorized, setAuthorized] = useState<boolean>(false);
  const [responseInterceptorUsed, setResponseInterceptorUsed] = useState<boolean>(false);
  const router = useRouter();
  // Set the authorization header
  setApiAuthorizationHeader(authData?.token);

  const authCheck = (url: string) => {
    const publicPaths = ['/login'];
    const path = url.split('?')[0];
    if (authData === null && !publicPaths.includes(path)) {
      setAuthorized(false);
      router.push({
        pathname: '/login',
        query: { redirectUrl: router.asPath },
      });
    } else {
      setAuthorized(true);
    }
  };

  useEffect(() => {
    authCheck(router.pathname);

    return () => {
      setAuthorized(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.pathname]);

  const saveAuthData = (data: AuthData) => {
    setAuthData(data);
    setAuthorized(true);
  };

  const resetAuthData = () => {
    setAuthData(null);
    setAuthorized(false);
  };

  /** Initialize the refresh token interceptor */
  useApiResponseInterceptor({
    isAuthorized,
    authData,
    saveAuthData,
    resetAuthData,
    createdHandler: () => setResponseInterceptorUsed(true),
    ejectedHandler: () => setResponseInterceptorUsed(false),
  });

  return (
    <AuthContext.Provider value={{ isAuthorized, authData, saveAuthData, resetAuthData }}>
      {isAuthorized && (responseInterceptorUsed || !authData) && children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
