import { useMutation, useQuery } from 'react-query';
import { API } from '@utils/api-config';
import camelCase from 'camelcase-keys';
import { showAlert } from '@swvl/alert';
import { AxiosResponse } from 'axios';
import { getErrorMessage } from '@utils';
import { APIErrorResponse } from '@shared/login/resources';

type QueryParams = {
  page?: number;
  limit?: number;
  createWhiteListLoading?: boolean;
  tableLoader?: boolean;
  cityId?: string;
  categoryId?: string;
  hexSearchId?: string;
};

export type CitySource = {
  id: string;
  city: string;
  name: string;
};

export type City = {
  _id: string;
  _source: {
    _id: string;
    city: string;
    name: string;
    name_i18n: { ar: string; en: string };
  };
};

export type TableRowElem = {
  id?: string;
  createdBy?: string;
  createdAt?: string;
  updatedByName?: string;
  maxFare?: number;
  pricingType?: string;
  fareType?: string;
  status?: string;
  name?: string;
  row?: {
    id?: string;
  };
  original?: {
    status?: string;
  };
};

export type RowConfig = {
  active: boolean;
  categoryId: string;
  cityId: string;
  createdAt: string;
  createdBy: string;
  name: string;
  pricingConfig: {
    baseDistance: number;
    baseFare: number;
    baseTime: number;
    distanceBucketFare: number;
    distanceBucketSize: number;
    fareType: string;
    maxPeak: number;
    maximumFare: number;
    minPeak: number;
    peakMultiplierStep: number;
    pricingModel: string;
    roundingOffLimit: number;
    timeBucketFare: number;
    timeBucketSize: number;
  };
  updatedAt: string;
  updatedBy: string;
  updatedByName: string;
  _id: string;
};

export type Featurelist = {
  category: string;
  features: any[];
  ride_type: string;
  service_type: string;
  _id: string;
};

export type FormValues = {
  min_peak?: number;
  max_peak?: number;
  peak_multiplier_step?: number;
  rounding_off_limit?: number;
  rideCategory?: string;
  cityId?: string;
  currency?: string;
};

export type FareTiersType = {
  baseFare?: number;
  maxFare?: number;
  baseDistance?: number;
  distanceBucketSize?: number;
  distanceBucketFare?: number;
  baseTime?: number;
  timeBucketSize?: number;
  timeBucketFare?: number;
};

export type TierData = {
  configName: string;
  fareTiers: FareTiersType;
  fareTyp?: string;
  priceMod?: string;
  file: any;
  fileName: string;
};

export type SelectionResult = {
  label: string;
  value: string;
};

export type WhiteListType = {
  configName: string;
  fareTiers: FareTiersType;
  priceMod: string;
  fareTyp: string;
};

type CityAutocompleteAPIResponse = {
  hits: City[];
  total: number;
};

type RideCategory = {
  _id: string;
  _source: {
    _id: string;
    ride_type: string;
    service_type: string;
    name_i18n: { ar: string; en: string };
  };
};

type RideCategoryAPIResposne = {
  hits: RideCategory[];
  total: number;
};

type CustomHex = {
  _id: string;
  _source: {
    _id: string;
    pricingConfig: {
      baseFare: number;
      distanceBucketSize: number;
      distanceBucketFare: number;
      timeBucketSize: number;
      timeBucketFare: number;
      maximumFare: number;
      roundingOffLimit: number;
      baseDistance: number;
      baseTime: number;
      minPeak: number;
      maxPeak: number;
      peakMultiplierStep: number;
      pricingModel: string;
      fareType: string;
    };
    cityId: string;
    categoryId: string;
    name: string;
    active: boolean;
    createdBy: string;
    createdAt: string;
    updatedByName: string;
  };
};

type CustomHexAPIResponse = {
  hits: CustomHex[];
  total: number;
};

const RideCategoryTransformer = (data: RideCategoryAPIResposne) =>
  camelCase(
    {
      ...data,
      hits: data.hits.map(item => ({ ...item, ...item._source })),
    },
    { deep: true },
  );

const cityAutocompleteDateTransformer = (data: CityAutocompleteAPIResponse) =>
  camelCase(
    {
      ...data,
      hits: data.hits.map(item => ({ ...item, ...item._source })),
    },
    { deep: true },
  );

const CustomHexTransformer = (data: CustomHexAPIResponse) =>
  camelCase(
    {
      ...data,
      hits: data.hits.map(item => ({ ...item, ...item._source })),
    },
    { deep: true },
  );

const autocompleteCity = async (query: string) => {
  const { data } = await API.get<CityAutocompleteAPIResponse>(`city/autocomplete`, {
    params: {
      query: query,
    },
  });
  return cityAutocompleteDateTransformer(data);
};

export const useAutocompleteCity = (query: string) => {
  return useQuery(['city/autocomplete', query], () => autocompleteCity(query));
};

const fetchCategories = async (_query?: string) => {
  const { data } = _query ? await API.get(`ride_category?city=${_query}`) : null;

  return data ? RideCategoryTransformer(data) : null;
};

export const useCategories = (query?: string) => {
  return useQuery(['ride_category', query], () => fetchCategories(query));
};

const fetchHexesWhitelist = async ({ page, limit, cityId, categoryId, hexSearchId }: Partial<QueryParams>) => {
  const { data } = await API.get(
    `/pricing/custom/hexes?page=${page}&limit=${limit}&city=${cityId}&category=${categoryId}&_id=${hexSearchId}`,
  );
  return CustomHexTransformer(data);
};

export const useHexesWhitelist = ({
  page,
  limit,
  createWhiteListLoading,
  tableLoader,
  cityId,
  categoryId,
  hexSearchId,
}: Partial<QueryParams>) => {
  return useQuery(
    ['hexWhiteList', page, limit, createWhiteListLoading, tableLoader, cityId, categoryId, hexSearchId],
    () => fetchHexesWhitelist({ page, limit, cityId, categoryId, hexSearchId }),
  );
};

const createWhiteList = async (formData: any) => {
  const response = await API.post<any>(`/pricing/custom/hexes/`, formData);
  return response;
};

export const useCreateWhiteList = () => {
  const mutation = useMutation(createWhiteList, {
    mutationKey: 'pricing/custom/hexes/',
    onSuccess: () => {
      showAlert({
        id: 'pricing-config-create',
        message: 'Successfully saved city level configurations',
        type: 'success',
        mode: 'dark',
      });
    },
    onError: (response: AxiosResponse) => {
      const message = getErrorMessage(response);
      showAlert({
        id: 'pricing-config-create',
        message,
        type: 'error',
        mode: 'dark',
      });
    },
  });

  return mutation;
};

const fetchCityDetail = async (_query?: string) => {
  const { data } = _query ? await API.get(`city/${_query}`) : null;

  return data ? { rideCategories: data.ride_categories, currency: data.currency } : null;
};

export const useCityDetail = (query?: string) => {
  return useQuery(['city_detail', query], () => fetchCityDetail(query));
};

const createCityConfig = async (data: any) => {
  const cityId = data.cityId;
  const categoryId = data.categoryId;
  delete data.cityId;
  delete data.categoryId;
  const response = await API.put<any>(`/city/${cityId}/category/${categoryId}/cityCategoryConfig`, data);
  return response;
};

export const useCreateCityConfig = () => {
  const mutation = useMutation(createCityConfig, {
    mutationKey: 'pricing/custom/hexes/',
    onSuccess: () => {
      showAlert({
        id: 'city-config-create',
        message: 'Configurations saved successfully',
        type: 'success',
        mode: 'dark',
      });
    },
    onError: (response: AxiosResponse) => {
      const message = getErrorMessage(response);
      showAlert({
        id: 'city-config-create',
        message,
        type: 'error',
        mode: 'dark',
      });
    },
  });

  return mutation;
};

const updateConfigStatus = async (data: any) => {
  const hexId = data.hexId;
  delete data.hexId;
  const response = await API.patch<any>(`/pricing/custom/hexes/${hexId}/active`, data);
  return response;
};

export const useUpdateConfigStatus = (
  { onSuccessCb }: { onSuccessCb: () => void },
  { onErrorCB }: { onErrorCB: (response: AxiosResponse) => void },
) => {
  const mutation = useMutation(updateConfigStatus, {
    mutationKey: 'pricing/custom/hexes/',
    onSuccess: () => {
      showAlert({
        id: 'city-config-update',
        message: 'Configurations updated successfully',
        type: 'success',
        mode: 'dark',
      });
      onSuccessCb();
    },
    onError: (response: AxiosResponse) => {
      const message = getErrorMessage(response);
      showAlert({
        id: 'city-config-update',
        message,
        type: 'error',
        mode: 'dark',
      });
      onErrorCB(response);
    },
  });

  return mutation;
};

const deleteHexConfig = async (data: string) => {
  const response = await API.delete<any>(`/pricing/custom/hexes/${data}`);
  return response;
};

export const useDeleteHexConfig = ({ onSuccessCb }: { onSuccessCb: () => void }) => {
  const mutation = useMutation(deleteHexConfig, {
    mutationKey: 'pricing/custom/delete/',
    onSuccess: _response => {
      showAlert({
        id: 'city-config-delete',
        message: 'Configurations delete successfully',
        type: 'success',
        mode: 'dark',
      });
      onSuccessCb();
    },
    onError: (err: unknown) => {
      const error = err as { data: APIErrorResponse };
      const message = error.data.message;
      showAlert({
        id: 'city-config-delete',
        message,
        type: 'error',
        mode: 'dark',
      });
    },
  });

  return mutation;
};

const updateCityConfig = async (data: FormData) => {
  const hexId = data.get('hexId');
  data.delete('hexId');
  const response = await API.patch<any>(`pricing/custom/hexes/${hexId}`, data);
  return response;
};

export const useUpdateCityConfig = ({ onSuccessCb }: { onSuccessCb: () => void }) => {
  const mutation = useMutation(updateCityConfig, {
    mutationKey: 'pricing/custom/update/',
    onSuccess: _response => {
      showAlert({
        id: 'city-config-update',
        message: 'Configurations updated successfully',
        type: 'success',
        mode: 'dark',
      });
      onSuccessCb();
    },
    onError: (err: unknown) => {
      const error = err as { data: APIErrorResponse };
      const message = error.data.message;
      showAlert({
        id: 'city-config-update',
        message,
        type: 'error',
        mode: 'dark',
      });
    },
  });

  return mutation;
};

export const fetchHexDetail = async (_query?: string) => {
  const { data } = await API.get(`/pricing/custom/hexes/${_query}`);
  return data;
};
