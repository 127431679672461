import { useCallback, useEffect } from 'react';
import axios, { AxiosInstance } from 'axios';
import { useRouter } from 'next/router';
import { AuthData, useRefreshToken } from '@shared/login/resources';
import type { AuthContextType } from '@context/auth';
import { showAlert } from '@swvl/alert';
import { useCustomers } from '@context/customers';
import env from '@utils/env';

// types

type UseApiResponseInterceptorType = AuthContextType & {
  createdHandler: () => void;
  ejectedHandler: () => void;
};

const baseURL = env('API_URL');
const API: AxiosInstance = axios.create({
  baseURL,
  headers: {
    'Content-Type': 'application/json',
  },
});

// remove dashboard/ from API_URL
const accountDeletionBaseUrl = String((baseURL || '').replace('dashboard/', ''));
const API_ACCOUNT_DELETION: AxiosInstance = axios.create({
  baseURL: accountDeletionBaseUrl,
  headers: {
    'Content-Type': 'application/json',
  },
});

// Variables to control the state of refreshing on unauthorized requests fail, can't be used in the hook state
const REFRESH_TOKEN_URL = '/token/refresh';
const UNAUTHORIZED = 401;
let isRefreshing = false;
let failedRequestsCallBacks: ((newAuthData: AuthData) => void)[] = [];

export const useApiResponseInterceptor = ({
  authData,
  saveAuthData,
  resetAuthData,
  isAuthorized,
  createdHandler,
  ejectedHandler,
}: UseApiResponseInterceptorType) => {
  const { mutate: refreshToken } = useRefreshToken();
  const { setSelectedCorporate } = useCustomers();
  const router = useRouter();

  const retryRequests = useCallback(newAuthData => {
    isRefreshing = false;
    // Retry all failed requests
    failedRequestsCallBacks.forEach(retryRequest => retryRequest(newAuthData));
    // Empty the failed requests
    failedRequestsCallBacks = [];
    // Set the new auth data
    saveAuthData({ ...newAuthData });
  }, []);

  const handleSessionExpired = useCallback(() => {
    isRefreshing = false;
    showAlert({ id: 'session-expired-error', message: 'Session has been expired', type: 'error', mode: 'dark' });
    // Reset auth and corporate data in local storage and navigate to login
    resetAuthData();
    setSelectedCorporate(null);
    router.replace('/login');
  }, []);

  useEffect(() => {
    if (!isAuthorized || authData === null) return;
    const interceptor = API.interceptors.response.use(undefined, error => {
      if (error.response?.status === UNAUTHORIZED && error.config.url !== REFRESH_TOKEN_URL) {
        const originalRequest = error.config;

        // If already retried before, navigate back to login
        if (originalRequest._retry && !authData?.refreshToken) {
          return handleSessionExpired();
        }
        // Retry refreshing token for 1 time for any request other than the refresh token
        if (!isRefreshing && authData?.refreshToken) {
          isRefreshing = true;
          refreshToken(
            { refreshToken: authData?.refreshToken },
            {
              onSuccess: retryRequests,
              onError: handleSessionExpired,
            },
          );
        }
        // Add request to failed requests
        return new Promise(resolve => {
          failedRequestsCallBacks.push(newAuthData => {
            // Mark it as retried before
            originalRequest._retry = true;
            // Set the new header token
            originalRequest.headers.Authorization = `Bearer ${newAuthData.token}`;
            // Retry request
            resolve(API.request(originalRequest));
          });
        });
      }

      return Promise.reject(error.response);
    });
    createdHandler();
    return () => {
      API.interceptors.response.eject(interceptor);
      ejectedHandler();
    };
  }, [authData, isAuthorized]);
};

export const setApiAuthorizationHeader = (token?: string) => {
  if (token) {
    API.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  } else {
    delete API.defaults.headers.common['Authorization'];
  }
};

export { API, API_ACCOUNT_DELETION };
