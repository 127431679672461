import { useContext, createContext, PropsWithChildren, useState, SetStateAction, Dispatch } from 'react';
import noop from 'lodash/noop';
import { PermissionModule } from '@access-control/roles/types';

type NewRoleContextProps = {
  roleName: string;
  roleDescription: string;
  roleNamespace: string;
  modules: PermissionModule[];
  setModules: Dispatch<SetStateAction<PermissionModule[]>>;
  setRoleName: Dispatch<SetStateAction<string>>;
  setRoleDescription: Dispatch<SetStateAction<string>>;
  setRoleNamespace: Dispatch<SetStateAction<string>>;
  reset: () => void;
};

const NewRoleContext = createContext<NewRoleContextProps>({
  roleName: '',
  roleDescription: '',
  roleNamespace: '',
  modules: [],
  setModules: noop,
  setRoleName: noop,
  setRoleDescription: noop,
  setRoleNamespace: noop,
  reset: noop,
});

export const NewRoleProvider = ({ children }: PropsWithChildren<unknown>) => {
  const [roleName, setRoleName] = useState('');
  const [roleDescription, setRoleDescription] = useState('');
  const [roleNamespace, setRoleNamespace] = useState('');
  const [modules, setModules] = useState([]);

  const reset = () => {
    setRoleDescription('');
    setRoleName('');
    setModules([]);
    setRoleNamespace('');
  };

  return (
    <NewRoleContext.Provider
      value={{
        roleName,
        roleDescription,
        roleNamespace,
        modules,
        setModules,
        setRoleName,
        setRoleDescription,
        setRoleNamespace,
        reset,
      }}
    >
      {children}
    </NewRoleContext.Provider>
  );
};

export const useNewRole = () => useContext(NewRoleContext);
