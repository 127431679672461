import React, { FC, ReactElement, ReactNode, useEffect, useMemo } from 'react';
import { init, setUserId } from '@amplitude/analytics-browser';
import { Global } from '@emotion/react';
import * as Sentry from '@sentry/browser';
import { hotjar } from 'react-hotjar';
import env from '@beam-australia/react-env';

import type { NextPage } from 'next';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import Link, { LinkProps } from 'next/link';
import { useRouter, NextRouter } from 'next/router';
import TagManager from 'react-gtm-module';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

import { AlertWrapper } from '@swvl/alert';
import BreadcrumbsWrapper from '@swvl/breadcrumbs';
import { Button } from '@swvl/button';
import { CanPermission } from '@swvl/gandalf-can';
import theme, { ThemeProvider } from '@swvl/theme';

import { AppProvider } from '@context/app';
import { AuthProvider } from '@context/auth';
import { CanProvider } from '@context/can';
import { UserProvider } from '@context/user';
import Can from '@shared/can-wrapper';
import { RestrictedRoutes } from '@shared/constants';
import { AUTH_DATA_KEY } from '@shared/constants';
import ErrorOverLay from '@shared/error-overlay';
import type { AuthData } from '@shared/login/resources';
import RestrictRouteGuard from '@shared/restrict-route-guard';
import { routingModules } from '@utils/routing-config';
import { useLocalStorage } from '@hooks/use-local-storage';

// CSS
import 'modern-normalize/modern-normalize.css';
import '@fontsource/public-sans/index.css';
import '@fontsource/public-sans/500.css';

const GlobalStyles = () => (
  <Global
    styles={() => ({
      'html, body': {
        fontFamily: theme.fonts.body,
        padding: 0,
        margin: 0,
      },
      a: {
        color: 'inherit',
        textDecoration: 'none',
      },
      '*::-webkit-scrollbar': {
        width: '0.5em',
        height: '0.5em',
      },
      '*::-webkit-scrollbar-track': {
        boxShadow: 'inset 0 0 6px rgba(0,0,0, 0.2)',
        borderRadius: '2px',
      },

      '*::-webkit-scrollbar-thumb': {
        backgroundColor: 'rgba(200, 200, 200, 0.6)',
        borderRadius: '2px',
      },
    })}
  />
);

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const isDev = process.env.NODE_ENV !== 'production';

export type PageCustomProps = NextPage & {
  hasLayout?: boolean;
  pageTitle?: string;
  breadcrumbs?: (router: NextRouter) => {
    label: string;
    href?: LinkProps['href'];
  }[];
  authorizationPermission?: CanPermission; // to check if user has access to this page or not
};

const ConditionalWrapper: FC<{
  condition: boolean;
  wrapper: (children: React.ReactElement) => JSX.Element;
  children: React.ReactElement;
}> = ({ condition, wrapper, children }) => (condition ? wrapper(children) : children);

export default function App({
  Component,
  pageProps,
}: AppProps & {
  Component: PageCustomProps;
}) {
  const { storedValue: authData } = useLocalStorage<AuthData | null>(AUTH_DATA_KEY);
  const { hasLayout = true, pageTitle, breadcrumbs, authorizationPermission } = Component;
  const router = useRouter();
  const activeModule = useMemo(() => {
    return Object.keys(routingModules).find(moduleKey => router.pathname.startsWith(routingModules[moduleKey].path));
  }, [router.pathname]);

  Sentry.setTag('module', activeModule);

  useEffect(() => {
    if (process.env.NEXT_PUBLIC_GTM_ID)
      TagManager.initialize({
        gtmId: process.env.NEXT_PUBLIC_GTM_ID,
      });
    if (env('AMPLITUDE_ID')) {
      try {
        init(env('AMPLITUDE_ID'));

        if (authData && authData.owner) {
          setUserId(authData.owner);
        }
      } catch (error) {
        console.error(error);
      }
    }
    // init hotjar
    if (env('HJ_ID')) hotjar.initialize(Number(env('HJ_ID')), 6);
  }, []);

  const Breadcrumbs = () => {
    if (!breadcrumbs) return null;

    return (
      <div sx={{ mb: 'spacing-s' }}>
        <BreadcrumbsWrapper>
          {breadcrumbs(router).map(({ label, href }) => {
            if (!href) return <span key={label}>{label}</span>;
            return (
              <Link key={label} href={href}>
                <a>{label}</a>
              </Link>
            );
          })}
        </BreadcrumbsWrapper>
      </div>
    );
  };
  const PageTitle = ({ children }: { children: ReactNode }) => (
    <h1 sx={{ p: 0, m: 0, mb: 'spacing-s', variant: 'text.h4' }}>{children}</h1>
  );

  return (
    <>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={theme}>
          <AlertWrapper position="bottom-center" />
          <AuthProvider>
            <CanProvider>
              <AppProvider>
                <UserProvider>
                  <Head>
                    <title>SaaS Platform</title>
                    <meta name="viewport" content="initial-scale=1.0, width=device-width" />
                  </Head>
                  {authorizationPermission ? (
                    <Can
                      verb="a"
                      permission={authorizationPermission}
                      notAuthorizedComponent={
                        <ErrorOverLay
                          code={403}
                          message={`Oops, you don't have access to this page. Please contact the Administrator.`}
                        >
                          <Button
                            variant="secondary"
                            size="medium"
                            onClick={() => {
                              router.push('/customers/reservations');
                            }}
                          >
                            {'Return to Home'}
                          </Button>
                        </ErrorOverLay>
                      }
                    >
                      <Main hasLayout={hasLayout} activeModule={activeModule}>
                        <>
                          <Breadcrumbs />
                          {pageTitle ? <PageTitle>{pageTitle}</PageTitle> : ''}
                          <Component {...pageProps} />
                        </>
                      </Main>
                    </Can>
                  ) : (
                    <Main hasLayout={hasLayout} activeModule={activeModule}>
                      <>
                        <Breadcrumbs />
                        {pageTitle ? <PageTitle>{pageTitle}</PageTitle> : ''}
                        <Component {...pageProps} />
                      </>
                    </Main>
                  )}
                </UserProvider>
              </AppProvider>
            </CanProvider>
          </AuthProvider>
        </ThemeProvider>
        {isDev ? <ReactQueryDevtools initialIsOpen={false} /> : ''}
      </QueryClientProvider>
      <GlobalStyles />
    </>
  );
}

// the point of Main is to initialize the auth and user hooks inside the QueryClientProvider
export const Main = ({
  children: mainChildren,
  hasLayout,
  activeModule,
}: {
  children: ReactElement;
  hasLayout: boolean;
  activeModule: string;
}) => {
  const router = useRouter();

  const ActiveModuleLayout = useMemo(() => {
    return routingModules[activeModule]?.layout || React.Fragment;
  }, [activeModule]);
  const ActiveSubModuleLayout = useMemo(() => {
    return (
      routingModules[activeModule]?.subModules.find(subModule => router.pathname.startsWith(subModule.path))?.layout ||
      React.Fragment
    );
  }, [activeModule, router.pathname]);
  return (
    <RestrictRouteGuard routes={RestrictedRoutes}>
      <ConditionalWrapper
        condition={hasLayout}
        wrapper={children => (
          <ActiveModuleLayout>
            <ActiveSubModuleLayout>{children}</ActiveSubModuleLayout>
          </ActiveModuleLayout>
        )}
      >
        {mainChildren}
      </ConditionalWrapper>
    </RestrictRouteGuard>
  );
};
