import { FC, useMemo } from 'react';
import { Menu, MenuItem } from '@swvl/menu';
import { usePlanning } from '@context/planning';
import { useUser } from '@context/user';
import Header from '@shared/header';
import { useLogout } from '@shared/login/resources';
import { useRouter } from 'next/router';
import { planningReservations } from '@shared/paths';

const PlanningHeader: FC = () => {
  // Contexts
  const { user } = useUser();
  const { selectedCorporateId, managedCorporates, setSelectedCorporate } = usePlanning();
  const { pathname } = useRouter();

  // Resources
  const { mutate: onLogout } = useLogout();

  // Memos
  const selectedCorporate = useMemo(
    () => managedCorporates.find(corporate => corporate.id === selectedCorporateId),
    [selectedCorporateId, managedCorporates],
  );

  return (
    <Header>
      <Header.Modules />
      <Header.SettingsWrapper>
        {pathname !== planningReservations ? (
          <Menu
            menuButton={
              <div
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  fontFamily: 'heading',
                }}
              >
                {selectedCorporateId ? selectedCorporate?.name : 'Select Corporate'}
              </div>
            }
            data-test-name="corporates-menu"
          >
            {managedCorporates.map(it => {
              return (
                <MenuItem
                  onClick={() => {
                    setSelectedCorporate(it.id);
                  }}
                  key={it.id}
                  data-test-name="corporates-menu-item"
                  data-test-id={it.id}
                >
                  {it.name}
                </MenuItem>
              );
            })}
          </Menu>
        ) : null}
        <Header.AccountMenu
          user={{
            name: user?.name,
            picture: user?.picture,
          }}
          onLogout={onLogout}
        />
      </Header.SettingsWrapper>
    </Header>
  );
};

export default PlanningHeader;
