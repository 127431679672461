import { useContext, createContext, PropsWithChildren, useState, useEffect } from 'react';
import { useUser } from '@context/user';
import useFilters from '@hooks/use-filters';

import { useRouter } from 'next/router';
import { useLocalStorage } from '@hooks/use-local-storage';
type DemandContextProps = {
  cityId: string;
  setCurrentCityId: (filters: Partial<string>) => void;
  selectedCorporateId: string | null;
  setSelectedCorporate: (corporate: string | null) => void;
  myCorporates: { id: string; name: string; shiftReservationEnabled: boolean }[] | [];
};

type DemandFiltersProps = {
  corporate: string | null;
  cityId: string;
};
const DemandContext = createContext<DemandContextProps>({
  cityId: null,
  setCurrentCityId: () => undefined as void,
  selectedCorporateId: null,
  setSelectedCorporate: () => undefined as void,
  myCorporates: [],
});

export const DemandProvider = ({ children }: PropsWithChildren<unknown>) => {
  const { user } = useUser();
  const router = useRouter();
  const isDemandModule = router.asPath.includes('demand');

  const { setValue: setStoredCorporate, storedValue: storedCorporateId } = useLocalStorage<string | null>(
    '__SELECTED__DEMAND_CORPORATE__',
  );

  const { setValue: setCurrentCityId, storedValue: cityId } = useLocalStorage<string | null>('__SELECTED_CITY__');
  const myCorporates = user?.accountManagedCorporates ?? [];
  const [demandFilters, setDemandFilters] = useFilters<DemandFiltersProps>(
    {
      corporate: storedCorporateId,
      cityId,
    },
    isDemandModule,
  );
  useEffect(() => {
    setCurrentCityId(user?.city.id);
  }, [user]);
  useEffect(() => {
    setCurrentCityId(demandFilters.cityId);
  }, [demandFilters.cityId]);
  useEffect(() => {
    setStoredCorporate(demandFilters.corporate);
  }, [demandFilters.corporate]);

  return (
    <DemandContext.Provider
      value={{
        cityId: demandFilters.cityId,
        selectedCorporateId: demandFilters.corporate,
        myCorporates,
        setCurrentCityId: city => {
          if (city) setDemandFilters({ ...demandFilters, cityId });
        },
        setSelectedCorporate: corporate => {
          if (corporate) setDemandFilters({ ...demandFilters, corporate });
        },
      }}
    >
      {children}
    </DemandContext.Provider>
  );
};

export const useDemand = () => useContext(DemandContext);
