import TagManager from 'react-gtm-module';

type DataLayerProperties = {
  [key: string]: string | number;
};
/**
 * a generic function for shift creation instrumentations
 * @param event refer to event name (string)
 * @param additionalProperties refer to any additional data to send with the dataLayer
 */

export const instrumentationsHandler = (event: string, additionalProperties?: DataLayerProperties) => {
  let dataLayer = {
    event,
  };
  if (additionalProperties) {
    dataLayer = { ...dataLayer, ...additionalProperties };
  }
  TagManager.dataLayer({ dataLayer });
};
