import { FC } from 'react';

type errorOverlayProps = {
  children: React.ReactChild | React.ReactChild[];
  code: 403 | 404;
  message: string;
};
const ErrorOverLay: FC<errorOverlayProps> = ({ code, message, children }): JSX.Element => (
  <div
    sx={{
      textAlign: 'center',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh',
      fontFamily:
        '-apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", "Fira Sans", Avenir, "Helvetica Neue", "Lucida Grande", sans-serif',
    }}
  >
    <div sx={{ mb: 16 }}>
      <h1
        sx={{
          display: 'inline-block',
          borderRight: `1px solid rgba(0, 0, 0, 0.3)`,
          m: 0,
          mr: 20,
          p: `10px 11px 10px 0px`,
          fontSize: '1.5rem',
          fontWeight: '500',
          verticalAlign: 'top',
        }}
      >
        {code}
      </h1>
      <div sx={{ display: 'inline-block', textAlign: 'left', height: 49, lineHeight: '49px', verticalAlign: 'middle' }}>
        <h2
          sx={{
            fontSize: '0.875rem',
            fontWeight: '400',
            p: 0,
            m: 0,
          }}
        >
          {message}
        </h2>
      </div>
    </div>
    <div>{children}</div>
  </div>
);
export default ErrorOverLay;
