import { setUserId } from '@amplitude/analytics-browser';
import camelCase from 'camelcase-keys';
import { useRouter } from 'next/router';
import { useMutation } from 'react-query';
import { useQueryClient } from 'react-query';
import { useAuth } from '@context/auth';
import { useCustomers } from '@context/customers';
import { API, API_ACCOUNT_DELETION } from '@utils/api-config';

// Types
export type APIErrorResponse = {
  statusCode: number;
  error: string;
  message: string;
};

export type LoginAPIResponse = {
  _id: string;
  owner: string;
  type: string;
  token: string;
  refresh_token: string;
  expiry_date: string;
  createdAt: string;
  updatedAt: string;
  __v: number;
  is_expired: boolean;
  id: string;
  jwt: string;
  role: string;
  roles: { reference_id: string; name: string }[] | null;
};

export type CustomerLoginAPIResponse = {
  user: {
    id: string;
    phone: string;
    phone_data: {
      national_number: string;
      region_code: string;
      country_code: string;
    };
    name: string;
    email: string;
    is_verified: boolean;
  };
};

export type RefreshTokenAPIResponse = Omit<LoginAPIResponse, 'roles'>;

export type AuthData = ReturnType<typeof authDataTransformer>;

// Transforms
const authDataTransformer = (data: LoginAPIResponse | RefreshTokenAPIResponse) => camelCase(data, { deep: true });

// Resources
const login = async (loginData: { email: string; password: string }) => {
  const { data } = await API.post<LoginAPIResponse>('/sign_in', loginData);
  return authDataTransformer(data);
};

const loginCaptain = async (loginData: { phone: string; pin: string; country_code: string; region_code: string }) => {
  const { data } = await API_ACCOUNT_DELETION.post<LoginAPIResponse>('captain/v2/sign_in', {
    pin: loginData.pin,
    phone_data: {
      national_number: loginData.phone,
      country_code: loginData.country_code,
      region_code: loginData.region_code,
    },
  });
  return authDataTransformer(data);
};

const loginCustomer = async (loginData: {
  phone: string;
  password: string;
  country_code: string;
  region_code: string;
}) => {
  const { data } = await API_ACCOUNT_DELETION.post<CustomerLoginAPIResponse>('/user/v2/signin', {
    password: loginData.password,
    phone_data: {
      national_number: loginData.phone,
      country_code: loginData.country_code,
      region_code: loginData.region_code,
    },
  });

  return camelCase(data, { deep: true });
};

const logout = async (logoutData: { refresh_token: string }) => {
  const response = await API.post<Record<never, unknown>>('/sign_out', logoutData);
  return response;
};

const refresh = async ({ refreshToken }: { refreshToken: string }) => {
  const { data } = await API.post<RefreshTokenAPIResponse>('/token/refresh', {
    refresh_token: refreshToken,
  });
  return authDataTransformer(data);
};

// Hooks
export const useLogin = () => {
  const { saveAuthData } = useAuth();
  const router = useRouter();

  const mutation = useMutation(login, {
    mutationKey: 'user/login',
    onSuccess: data => {
      saveAuthData(data);
      setUserId(data.owner);

      router.replace((router.query.redirectUrl as string) || '/');
    },
  });
  return {
    ...mutation,
  };
};

export const useLoginCaptain = () => {
  const mutation = useMutation(loginCaptain, {
    mutationKey: 'user/login/captain',
  });
  return {
    ...mutation,
  };
};

export const useLoginCustomer = () => {
  const mutation = useMutation(loginCustomer, {
    mutationKey: 'user/login/customer',
  });
  return {
    ...mutation,
  };
};

export const useLogout = () => {
  const { resetAuthData } = useAuth();
  const { setSelectedCorporate } = useCustomers();
  const router = useRouter();
  const queryClient = useQueryClient();

  const removeAccessControlQueries = () => {
    // Remove queries keys from cache in logout so not authorized users can see
    // previous authorized users cache data
    queryClient.removeQueries();
  };

  const mutation = useMutation(logout, {
    mutationKey: ['user/logout'],
    onSuccess: () => {
      resetAuthData();
      setSelectedCorporate(null);
      removeAccessControlQueries();
      router.replace('/login');
    },
    onError: () => {
      resetAuthData();
      setSelectedCorporate(null);
      removeAccessControlQueries();
      router.replace('/login');
    },
  });
  return {
    ...mutation,
  };
};

export const useRefreshToken = () => {
  const { saveAuthData, resetAuthData } = useAuth();
  const { setSelectedCorporate } = useCustomers();
  const router = useRouter();

  const mutation = useMutation(refresh, {
    mutationKey: 'user/refresh-token',
    onSuccess: response => {
      saveAuthData({ ...response, roles: null });
    },
    onError: () => {
      resetAuthData();
      setSelectedCorporate(null);
      router.replace('/login');
    },
  });

  return mutation;
};
