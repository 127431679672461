import { FC } from 'react';
import { useUser } from '@context/user';
import Header from '@shared/header';
import { useLogout } from '@shared/login/resources';

const PrivateBusHeader: FC = () => {
  const { user } = useUser();
  const { mutate: onLogout } = useLogout();

  return (
    <Header>
      <Header.Modules />
      <Header.SettingsWrapper>
        <Header.AccountMenu
          user={{
            name: user?.name,
            picture: user?.picture,
          }}
          onLogout={onLogout}
        />
      </Header.SettingsWrapper>
    </Header>
  );
};

export default PrivateBusHeader;
