export enum ROUTING_STATUS {
  EMPLOYEES = 'employees',
  GROUPS = 'groups',
}

export enum EMPLOYEE_VIEW {
  ADD_EMPLOYEE = 'add_employee',
  EDIT_EMPLOYEE = 'edit_employee',
  BULK_UPDATE_EMPLOYEES = 'bulk_update_employees',
}

export const bulkActionsOptions: {
  label: string;
  value: string;
}[] = [
  { label: 'Update Employees', value: 'update_business_profiles' },
  { label: 'Add Employees', value: 'create_business_profiles' },
];

export enum GENDER {
  MALE = 'male',
  FEMALE = 'female',
}
