import { saasModulesPermissions } from '@shared/constants';

export const namespacesList = [
  {
    label: 'SaaS Platform',
    value: 'SaaS_Platform',
  },
];

export const AUTOCOMPLETE_LOAD_AFTER = 2;

export const ROLE_ACTIONS = {
  edit: 'edit',
  copy: 'copy',
};

export const QUERY_RETRY_COUNT = 3;

export const PAGES_PERMISSIONS = {
  users: {
    permission: saasModulesPermissions['access-control'].viewUsersModule,
    attributes: {},
  },
  roles: {
    permission: saasModulesPermissions['access-control'].viewRolesModule,
    attributes: {},
  },
  'access-control': {
    permission: saasModulesPermissions['access-control'].viewModule,
    attributes: {},
  },
};

export const PERSONA_SCOPE = 'saas';
