import * as Sentry from '@sentry/nextjs';
import env from '@utils/env';

const SENTRY_DSN = env('SENTRY_DSN');

if (SENTRY_DSN) {
  Sentry.init({
    dsn: SENTRY_DSN,
    tracesSampleRate: 1.0,
  });
}
