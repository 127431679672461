export enum ROUTING_STATUS {
  PENDING = 'pending',
  COMPLETED = 'completed',
  IN_PROGRESS = 'in-progress',
  FAILED = 'failed',
}

export enum SELECTED_DATE_TAB {
  TODAY = 'Today',
  TOMORROW = 'Tomorrow',
  CUSTOM = 'Custom',
}

export enum DIRECTION {
  FROM_OFFICE = 'from_office',
  TO_OFFICE = 'to_office',
}
