import { PropsWithChildren } from 'react';
import { useRouter } from 'next/router';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { CustomersProvider } from '@context/customers';
import CustomersHeader from '@customers/header';
import { useApp } from '@context/app';
import { Sidebar } from '@shared/sidebar';

export const CustomersLayout = ({ children }: PropsWithChildren<unknown>) => {
  const router = useRouter();
  const { isSidebarOpen } = useApp();

  return (
    <CustomersProvider>
      <CustomersHeader />
      <DndProvider backend={HTML5Backend}>
        <main
          sx={{
            display: 'flex',
            height: '100%',
            aside: {
              minWidth: !isSidebarOpen && '80px !important',
            },
          }}
        >
          <>
            <Sidebar />
            <section
              sx={{
                flex: 1,
                padding:
                  router.pathname.includes('recommended-map') || router.pathname.includes('single-ride-map')
                    ? 0
                    : 'spacing-m',
              }}
            >
              {children}
            </section>
          </>
        </main>
      </DndProvider>
    </CustomersProvider>
  );
};
