import { useState, createContext, useContext, PropsWithChildren, useEffect } from 'react';
import { CanClient } from '@swvl/gandalf-can';
import { fetchUserBundle } from '@access-control/users/resources';
import { useAuth } from '@context/auth';
import { Spinner } from 'theme-ui';

export type CanContextType = {
  canClient: CanClient;
};

export const CanContext = createContext<CanContextType>({
  canClient: null,
});

export const CanProvider = ({ children }: PropsWithChildren<unknown>) => {
  const [canClient, setCanClient] = useState<CanClient>();
  const [isLoadingData, setIsLoadingData] = useState(true);
  const { authData } = useAuth();
  useEffect(() => {
    let canClient: CanClient;
    if (authData) {
      setIsLoadingData(true);
      if (!canClient) {
        canClient = new CanClient({
          loadData: async () => {
            try {
              const data = await fetchUserBundle();
              return data;
            } catch (err) {
              throw new Error(err);
            }
          },
          ttl: 20,
        });
        setCanClient(canClient);
      } else {
        canClient.reload();
      }
      canClient.on('ready', () => {
        setIsLoadingData(false);
      });

      canClient.on('error', () => {
        setIsLoadingData(false);
      });

      canClient.on('reload', () => {
        setIsLoadingData(false);
      });
    } else {
      setIsLoadingData(false);
    }
  }, [authData]);

  if (isLoadingData)
    return (
      <div sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <Spinner color="secondary" />
      </div>
    );
  return <CanContext.Provider value={{ canClient }}>{children}</CanContext.Provider>;
};

export const useCan = () => useContext(CanContext);
