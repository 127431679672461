import { useState } from 'react';

export const useLocalStorage = <V>(
  key: string,
  initialValue?: V,
  onAccessError?: () => void,
  onSetError?: () => void,
) => {
  const [storedValue, setStoredValue] = useState<V>(() => {
    try {
      const item = window.localStorage.getItem(key);
      return item ? JSON.parse(item) : initialValue || null;
    } catch (error) {
      if (onAccessError) {
        onAccessError();
      }
      return initialValue;
    }
  });

  const setValue = (value: V | ((stored: V) => V)) => {
    try {
      const valueToStore = value instanceof Function ? value(storedValue) : value;
      setStoredValue(valueToStore);
      window.localStorage.setItem(key, JSON.stringify(valueToStore));
    } catch (error) {
      if (onSetError) {
        onSetError();
      }
    }
  };

  return { storedValue, setValue };
};
