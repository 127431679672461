import { PropsWithChildren } from 'react';
import { PlanningProvider } from '@context/planning';
import PlanningHeader from '@planning/header';
import { useApp } from '@context/app';
import { Sidebar } from '@shared/sidebar';

export const PlanningLayout = ({ children }: PropsWithChildren<unknown>) => {
  const { isSidebarOpen } = useApp();

  return (
    <PlanningProvider>
      <PlanningHeader />
      <main
        sx={{
          display: 'flex',
          height: '100%',
          aside: {
            minWidth: !isSidebarOpen && '80px !important',
          },
        }}
      >
        <>
          <Sidebar />
          <section sx={{ flex: 1, p: 'spacing-m' }}>{children}</section>
        </>
      </main>
    </PlanningProvider>
  );
};
