import { useQuery } from 'react-query';
import { API } from '@utils/api-config';
import camelCase from 'camelcase-keys';
import { useAuth } from '@context/auth';
import { ShiftDeadlineType } from '@context/planning';

// Types
export type APIResponse = {
  _id: string;
  phone: string;
  picture: string;
  name: string;
  email: string;
  active: boolean;
  role: string;
  city: {
    _id: string;
    name: string;
    center: { loc: { coordinates: number[]; type: string } };
  };
  phone_region_code: string;
  type: string;
  corporate: {
    _id: string;
    name: string;
    shift_type: string;
    city: string;
    fromOfficeShiftDeadline: {
      type: string;
      value: number;
    };
    toOfficeShiftDeadline: {
      type: string;
      value: number;
    };
  };
  corpRole: string;
  account_managed_corporates?: {
    _id: string;
    name: string;
    shift_reservation_enabled: boolean;
    allow_delete_all_business_profiles: boolean;
    shift_type: string;
    from_office_shift_deadline: {
      type: ShiftDeadlineType;
      value: number;
    };
    to_office_shift_deadline: {
      type: ShiftDeadlineType;
      value: number;
    };
  }[];
};

export type UserData = ReturnType<typeof userDataTransformer>;

// Transforms
const userDataTransformer = (data: APIResponse) => camelCase(data, { deep: true });

// Resources
const fetchUserInfo = async () => {
  const { data } = await API.get<APIResponse>('/me');

  return userDataTransformer(data);
};

// Hooks
export const useUserInfo = () => {
  const { authData } = useAuth();
  const token = authData?.token ?? null;

  // TODO: use auth to fetch user info
  return useQuery('userInfo', () => fetchUserInfo(), {
    enabled: !!token,
  });
};
