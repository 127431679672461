import { FC, useState, useEffect } from 'react';
import { CanPermission } from '@swvl/gandalf-can/dist/types';
import { useCan } from '@context/can';
import { showAlert } from '@swvl/alert';

type CanProps = {
  children: React.ReactChild | React.ReactChild[];
  notAuthorizedComponent?: React.FC | React.ReactElement;
} & (
  | {
      permission: CanPermission;
      verb: 'not' | 'a';
    }
  | { permission: CanPermission[]; verb: 'any' | 'all' }
);

const Can: FC<CanProps> = ({ children, permission, verb, notAuthorizedComponent }): JSX.Element => {
  const { canClient } = useCan();
  const [isEvaluating, setIsEvaluating] = useState(true);
  const [can, setCan] = useState(false);
  const isCanPermissionArray = (permission: CanPermission | CanPermission[]): permission is CanPermission[] => {
    return (permission as CanPermission[]).length !== undefined;
  };
  const evaluate = async () => {
    let result;
    try {
      if (isCanPermissionArray(permission)) {
        if (verb === 'any') result = await canClient.canAny(permission);
        else if (verb === 'all') result = await canClient.canAll(permission);
      } else {
        if (verb === 'a') result = await canClient.can(permission);
        else if (verb === 'not') result = await canClient.canNot(permission);
      }
      setCan(result);
      setIsEvaluating(false);
    } catch (error) {
      showAlert({
        id: 'can-evaluate',
        message: 'Something went wrong , try again!',
        variant: 'error',
        mode: 'dark',
        style: { wordBreak: 'break-all' },
      });
      setCan(true);
      setIsEvaluating(false);
    }
  };
  useEffect(() => {
    evaluate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return !isEvaluating && can ? <> {children} </> : notAuthorizedComponent ? <>{notAuthorizedComponent}</> : null;
};

export default Can;
